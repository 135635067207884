// src/components/forms/ButtonForm.js
import React from "react";
import ButtonPreview from "../previews/ButtonPreview";

export const getDefaultButtonConfig = () => ({
  label: "Click Me",
  backgroundColor: "#007bff",
  textColor: "#ffffff",
  padding: "10px 20px",
  borderRadius: "5px",
  fontSize: "16px",
  fontWeight: "normal",
  borderStyle: "none",
  hoverBackgroundColor: "#0056b3",
  hoverTextColor: "#ffffff",
});

const ButtonForm = ({ config, onConfigChange }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onConfigChange({ ...config, [name]: value });
  };

  return (
    <div>
      <h3>Button Component</h3>
      <label>
        Label:
        <input
          type="text"
          name="label"
          value={config.label}
          onChange={handleChange}
        />
      </label>
      <label>
        Background Color:
        <input
          type="color"
          name="backgroundColor"
          value={config.backgroundColor}
          onChange={handleChange}
        />
      </label>
      <label>
        Text Color:
        <input
          type="color"
          name="textColor"
          value={config.textColor}
          onChange={handleChange}
        />
      </label>
      <label>
        Padding:
        <input
          type="text"
          name="padding"
          value={config.padding}
          onChange={handleChange}
        />
      </label>
      <label>
        Border Radius:
        <input
          type="text"
          name="borderRadius"
          value={config.borderRadius}
          onChange={handleChange}
        />
      </label>
      <label>
        Font Size:
        <input
          type="text"
          name="fontSize"
          value={config.fontSize}
          onChange={handleChange}
        />
      </label>
      <label>
        Font Weight:
        <select
          name="fontWeight"
          value={config.fontWeight}
          onChange={handleChange}
        >
          <option value="normal">Normal</option>
          <option value="bold">Bold</option>
          <option value="lighter">Lighter</option>
        </select>
      </label>
      <label>
        Border Style:
        <select
          name="borderStyle"
          value={config.borderStyle}
          onChange={handleChange}
        >
          <option value="none">None</option>
          <option value="solid">Solid</option>
          <option value="dashed">Dashed</option>
          <option value="dotted">Dotted</option>
        </select>
      </label>
    </div>
  );
};

export default ButtonForm;
export { ButtonPreview };
