import React from 'react'
import useFormDate from '../../store/useFormDate';

const DateTimePickerPreview= ({config}) => {
    const {  dateTimeValue,setDateTimeValue }=useFormDate();
    console.log("dateValue:",  dateTimeValue);
    const {
        minDateTime, 
    maxDateTime, 
    placeholder, 
    defaultDateTime,
    width,
    label,
    disabled,
    required,
    tooltip,
    ActiveWhen,
    InactiveWhen,
    backgroundColor,
    borderRadius,
    }= config;
    
  return (
    <>
    <div style={{ marginBottom: "20px" }}>
            {label && <label>{label}</label>}
            <input

                
                
            
                type="datetime-local"
        
        placeholder={placeholder}
        min={minDateTime}
        max={maxDateTime}
        defaultValue={defaultDateTime}
        disabled={disabled}
                required={required}
                title={tooltip}
                onChange={(e) => setDateTimeValue(e.target.value)}
                style={{
                    width: `${width}%`,
                    backgroundColor,
                    borderRadius: `${borderRadius}px`,
                    padding: "8px",
                    border: "1px solid #ccc",
                    cursor: disabled ? 'not-allowed' : 'text',
                    opacity: disabled ? 0.6 : 1,

                }}
            />
        </div>
        </>
  )
}

export default DateTimePickerPreview;