import TextForm, { TextPreview, getDefaultTextConfig } from "./TextForm";
import ButtonForm, {
  ButtonPreview,
  getDefaultButtonConfig,
} from "./ButtonForm";
import ImageForm, { ImagePreview, getDefaultImageConfig } from "./ImageForm";
import DropdownForm, {
  DropdownPreview,
  getDefaultDropdownConfig,
} from "./DropdownForm";
import AutocompleteForm, {
  AutocompletePreview,
  getDefaultAutocompleteConfig,
} from "./AutocompleteForm";
import TextInputForm, {
  TextInputPreview,
  getDefaultInputConfig,
} from "./TextInputForm";
import TextAreaForm, {
  TextAreaPreview,
  getDefaultTextAreaConfig,
} from "./TextAreaForm";
import CardForm, { CardPreview, getDefaultCardConfig } from "./CardForm";
import ParagraphForm, { getDefaultParagraphConfig } from "./ParagraphForm";
import ParagraphPreview from "../previews/ParagraphPreview";
import SwitchForm, { getDefaultSwitchConfig, SwitchPreview } from "./SwitchForm";
import CheckboxForm, { CheckboxPreview, getDefaultCheckboxConfig } from "./CheckboxForm";
import NumberInputForm, { getDefaultNumberInputConfig } from "./NumberInputForm";
import NumberInputPreview from "../previews/NumberInputPreview";
import DatePickerForm, { getDefaultDatePickerConfig } from "./DatePickerForm";
import DatePickerPreview from "../previews/DatePickerPreview";
import TimePickerPreview from "../previews/TimePickerPreview";
import TimePickerForm, { getDefaultTimePickerConfig } from "./TimePickerForm";
import DateTimePickerForm,{getDefaultDateTimePickerFormConfig} from "./DateTimePickerForm";
import DateTimePickerPreview from "../previews/DateTimePickerPreview";
import ColorPickerForm, { getDefaultColorPickerConfig } from "./ColorPickerForm";
import ColorPickerPreview from "../previews/ColorPickerPreview";
import MultiselectForm, { getDefaultMultiselectConfig } from "./MultiselectForm";
import MultiselectPreview from "../previews/MultiselectPreview";
import RadioGroupForm, {
  RadioGroupPreview,
  getDefaultRadioGroupConfig,
} from "./RadioGroupForm";
import PasswordInputForm, { getDefaultPasswordInputConfig } from "./PasswordInputForm";
import PasswordInputPreview from "../previews/PasswordInputPreview";
import SliderForm, { getDefaultSliderConfig } from "./SliderForm";
import SliderPreview from "../previews/SliderPreview";
import RangeSliderForm, { getDefaultRangeSliderConfig } from "./RangeSliderForm";
import RangeSliderPreview from "../previews/RangeSliderPreview";



const formComponents = {
  Text: {
    form: TextForm,
    preview: TextPreview,
    getDefaultConfig: getDefaultTextConfig,
  },
  Button: {
    form: ButtonForm,
    preview: ButtonPreview,
    getDefaultConfig: getDefaultButtonConfig,
  },
  Image: {
    form: ImageForm,
    preview: ImagePreview,
    getDefaultConfig: getDefaultImageConfig,
  },
  Dropdown: {
    form: DropdownForm,
    preview: DropdownPreview,
    getDefaultConfig: getDefaultDropdownConfig,
  },
  Autocomplete: {
    form: AutocompleteForm,
    preview: AutocompletePreview,
    getDefaultConfig: getDefaultAutocompleteConfig,
  },
  Input: {
    form: TextInputForm,
    preview: TextInputPreview,
    getDefaultConfig: getDefaultInputConfig,
  },
  Textarea: {
    form: TextAreaForm,
    preview: TextAreaPreview,
    getDefaultConfig: getDefaultTextAreaConfig,
  },
  Card: {
    form: CardForm,
    preview: CardPreview,
    getDefaultConfig: getDefaultCardConfig,
  },
  Paragraph: {
    form: ParagraphForm,
    preview: ParagraphPreview,
    getDefaultConfig: getDefaultParagraphConfig,
  },
  ToggleSwitch: {
    form: SwitchForm,
    preview: SwitchPreview,
    getDefaultConfig: getDefaultSwitchConfig,
  },
  CheckBox: {
    form: CheckboxForm,
    preview: CheckboxPreview,
    getDefaultConfig: getDefaultCheckboxConfig,
  },
  NumberInput: {
    form: NumberInputForm,
    preview: NumberInputPreview,
    getDefaultConfig: getDefaultNumberInputConfig,
  },
  Date_Picker: {
    form: DatePickerForm,
    preview: DatePickerPreview,
    getDefaultConfig: getDefaultDatePickerConfig,
  },

  Time_Picker: {
    form: TimePickerForm,
    preview: TimePickerPreview,
    getDefaultConfig: getDefaultTimePickerConfig,
  },
  Date_Time_Picker: {
    form: DateTimePickerForm,
    preview: DateTimePickerPreview,
    getDefaultConfig: getDefaultDateTimePickerFormConfig,
  },
  ColorPicker:{
    form: ColorPickerForm,
    preview: ColorPickerPreview,
    getDefaultConfig: getDefaultColorPickerConfig,
  },
  MultiSelect_Dropdown:{
    form: MultiselectForm,
    preview: MultiselectPreview,
    getDefaultConfig: getDefaultMultiselectConfig,
  },
  RadioGroup: {
    form: RadioGroupForm,
    preview: RadioGroupPreview,
    getDefaultConfig: getDefaultRadioGroupConfig,
  },
  password: {
    form: PasswordInputForm,
    preview: PasswordInputPreview,
    getDefaultConfig: getDefaultPasswordInputConfig,
  },

  Slider:{
    form: SliderForm,
    preview: SliderPreview,
    getDefaultConfig: getDefaultSliderConfig,
  },
  Range_Slider:{
    form:RangeSliderForm,
    preview:RangeSliderPreview,
    getDefaultConfig: getDefaultRangeSliderConfig,

  },

  // Add other components as needed
};

export default formComponents;
