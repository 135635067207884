import React, { useState } from 'react';
import { FaCaretDown } from "react-icons/fa";
import "../NumberInput.css";

const AccordionItem = ({ children, Title, panelName }) => {
    const [expanded, setExpanded] = useState(null);

    const handleAccordionToggle = (panel) => {
        setExpanded(expanded === panel ? null : panel);
    };

    return (
        <>
            <div
                className={`accordion-item ${expanded === panelName ? "expanded" : ""}`}
                onClick={() => handleAccordionToggle(panelName)}
            >
                <div>
                    <h4>{Title}</h4>
                </div>
                <div className="down-arrow"><FaCaretDown /></div>
            </div>

            {expanded === panelName && (
                <div className="accordion-content">
                    {children}
                </div>
            )}


        </>
    );
};

export default AccordionItem;

export const Accordion = ({ children }) => {
    return (
        <div className="accordion">{children}</div>
    );
};