import axios from "axios";
// Base API URL from .env file
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
if (!API_BASE_URL) {
  console.error("API_BASE_URL is undefined. Check your .env.development file.");
}
// Generic API request function
const apiClient = axios.create({
  baseURL: `${API_BASE_URL}/api`,
  headers: {
    "Content-Type": "application/json",
  },
});

// CRUD Operations
export const fetchAllData = async (endpoint) => {
  try {
    const response = await apiClient.get(endpoint);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const fetchOneRecord = async (endpoint, id) => {
  try {
    const response = await apiClient.get(`${endpoint}/${id}.json`);
    const data = response.data;
    // Extract the object without the alphanumeric key
    const result =
      data && typeof data === "object" ? Object.values(data)[0] : data;

    console.log("Resolved Component Data:", result);
    return result;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const createData = async (endpoint, data) => {
  console.log("endpoint, data", endpoint, data);
  try {
    const response = await apiClient.post(endpoint, data);
    console.log("response.data", response.data);
    return response.data;
  } catch (error) {
    console.error("Error creating data:", error);
    throw error;
  }
};

export const updateData = async (endpoint, id, data) => {
  try {
    const response = await apiClient.put(`${endpoint}/${id}`, data);
    return response.data;
  } catch (error) {
    console.error("Error updating data:", error);
    throw error;
  }
};

export const removeData = async (endpoint, id) => {
  try {
    const response = await apiClient.delete(`${endpoint}/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting data:", error);
    throw error;
  }
};
