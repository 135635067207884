import apiURL from '../config/apiConfig';
import { BASE_URL } from '../utils/utils/constants';
import SendEmail from '../utils/SendEmail';


const SendEmailService = async ({ verifyToken, email }) => {

https: console.log("verifyToken,email", verifyToken, email);
  const verifyEmail = `https://api-uat.mudumbai.in/api/sendEmail?email=${email}&token=${verifyToken}`;
  const templateParams = {
    to_email: email,
    verify_Link: verifyEmail,
  };
  await SendEmail({
    serviceId: "service_8715ym8",
    templateId: "template_0h90aq7",
    userId: "GR1M3HK1I-dx7h-qU",
    templateParams,
  });
};

export default SendEmailService;
