import React, { useState } from "react";
import { FaCircle, FaCheckCircle } from "react-icons/fa";

const RadioGroupPreview = ({ config }) => {
    const {
        options,
        disabled,
        labelPosition,
        fontSize,
        color,
        fontFamily,
    } = config;

    // State for the currently selected option
    const [selectedOption, setSelectedOption] = useState(config.selectedOption || "");

    const handleSelectionChange = (option) => {
        if (!disabled) {
            setSelectedOption(option);
        }
    };

    const getLabelClass = (position) => {
        switch (position) {
            case "left":
                return "flex flex-row-reverse items-center space-x-reverse space-x-2";
            case "top":
                return "flex flex-col items-start space-y-1";
            case "right":
            default:
                return "flex items-center space-x-2";
        }
    };

    return (
        <div
            className="p-4 rounded-md border shadow-sm"
            style={{ fontSize: `${fontSize}px`, color, fontFamily }}
        >
            {options.map((option, index) => (
                <div
                    key={index}
                    className={`${getLabelClass(labelPosition)} mb-4`}
                >
                    <input
                        type="radio"
                        name="radioGroup"
                        value={option}
                        checked={option === selectedOption}
                        onChange={() => handleSelectionChange(option)}
                        disabled={disabled}
                        className="w-4 h-4 text-green-600 border-gray-300 focus:ring-green-500 disabled:opacity-50"
                    />
                    <span className="flex items-center gap-2">
                        {option === selectedOption ? (
                            <FaCheckCircle
                                className={`text-green-500 ${disabled ? "text-gray-300" : ""
                                    }`}
                            />
                        ) : (
                            <FaCircle
                                className={`text-gray-400 ${disabled ? "text-gray-300" : ""
                                    }`}
                            />
                        )}
                        <span className={`${disabled ? "text-gray-400" : ""}`}>
                            {option}
                        </span>
                    </span>
                </div>
            ))}
        </div>
    );
};

export default RadioGroupPreview;
