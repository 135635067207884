import React, { useState, useEffect } from 'react';

const NumberInputPreview = ({ config }) => {
    const {
        placeholder,
        min,
        max,
        step,
        width,
        label,

        disabled,
        required,
        tooltip,
        ActiveWhen,
        InactiveWhen,
        backgroundColor,
        borderRadius,
    } = config;

    const [value, setValue] = useState(config.value || '');

    useEffect(() => {
        if (config.value !== value) {
            setValue(config.value);
        }
    }, [config.value]);

    const handleChange = (event) => {
        const newValue = parseFloat(event.target.value);
        if (
            !isNaN(newValue) &&
            newValue >= min &&
            newValue <= max
        ) {
            setValue(newValue);
        }
    };

    return (
        <div style={{ marginBottom: "20px" }}>
            {label && <label>{label}</label>}
            <input

                type="number"
                placeholder={placeholder}
                value={value}
                min={min}
                max={max}
                step={step}
                disabled={disabled}
                required={required}
               
                title={tooltip}
                onChange={handleChange}
                style={{
                    width: `${width}%`,
                    backgroundColor,
                    borderRadius: `${borderRadius}px`,
                    padding: "8px",
                    border: "1px solid #ccc",
                    cursor: disabled ? 'not-allowed' : 'text',
                    opacity: disabled ? 0.6 : 1,

                }}
            />
        </div>
    );
};

export default NumberInputPreview;
