// import React from "react";
// import useFormDate from "../../store/useFormDate";

// const TimePickerPreview = ({config}) => {
//     const { timeValue,setTimeValue}=useFormDate();
//     console.log("dateValue:",timeValue);
//   const {
//     placeholder,
//     defaultTime,
//     timeFormat,
//     stepInterval,
//     minTime,
//     maxTime,
//     width,
//     label,
//     disabled,
//     required,
//     tooltip,
//     ActiveWhen,
//     InactiveWhen,
//     backgroundColor,
//     borderRadius,
//   } = config;
//   return (
//     <div>
//       <div style={{ marginBottom: "20px" }}>
//         {label && <label>{label}</label>}
//         <input
//           type="time"
//           defaultValue={defaultTime}
//           placeholder={placeholder}
//           min={minTime}
//           max={maxTime}
//           disabled={disabled}
//           required={required}
//           title={tooltip}
//           step={stepInterval * 60}
//           onChange={(e) => setTimeValue(e.target.value)}
//           style={{
//             width: `${width}%`,
//             backgroundColor,
//             borderRadius: `${borderRadius}px`,
//             padding: "8px",
//             border: "1px solid #ccc",
//             cursor: disabled ? "not-allowed" : "text",
//             opacity: disabled ? 0.6 : 1,
//           }}
//         />
//         <small>
//           Format: {timeFormat} | Step: {stepInterval} minutes | Min:{" "}
//           {minTime || "None"} | Max: {maxTime || "None"}
//         </small>
//       </div>
//     </div>
//   );
// };

// export default TimePickerPreview;
import React, { useState } from "react";
import useFormDate from "../../store/useFormDate";

const TimePickerPreview = ({ config }) => {
  const { timeValue, setTimeValue } = useFormDate();
  const [formattedTime, setFormattedTime] = useState("");

  const {
    placeholder,
    defaultTime,
    timeFormat,
    stepInterval,
    minTime,
    maxTime,
    width,
    label,
    disabled,
    required,
    tooltip,
    backgroundColor,
    borderRadius,
  } = config;

  // Function to format time from 24-hour to 12-hour if needed
  const formatTo12Hour = (time) => {
    if (!time) return "";
    const [hour, minute] = time.split(":");
    const isPM = parseInt(hour, 10) >= 12;
    const newHour = (parseInt(hour, 10) % 12) || 12;
    const period = isPM ? "PM" : "AM";
    return `${String(newHour).padStart(2, "0")}:${minute} ${period}`;
  };

  // Handle changes to time input
  const handleTimeChange = (e) => {
    const selectedTime = e.target.value;
    setTimeValue(selectedTime);
    setFormattedTime(
      timeFormat === "12-hour" ? formatTo12Hour(selectedTime) : selectedTime
    );
  };

  return (
    <div>
      <div style={{ marginBottom: "20px" }}>
        {label && <label>{label}</label>}
        <input
          type="time"
          defaultValue={defaultTime}
          placeholder={placeholder}
          
          disabled={disabled}
          required={required}
          title={tooltip}
          step={stepInterval * 60} // Convert step interval from minutes to seconds
          onChange={handleTimeChange}
          style={{
            width: `${width}%`,
            backgroundColor,
            borderRadius: `${borderRadius}px`,
            padding: "8px",
            border: "1px solid #ccc",
            cursor: disabled ? "not-allowed" : "text",
            opacity: disabled ? 0.6 : 1,
          }}
        />

      </div>
    </div>
  );
};

export default TimePickerPreview;
