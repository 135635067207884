import React from 'react'
import useFormDate from '../../store/useFormDate';

const DatePickerPreview = ({config}) => {
    const { dateValue,setDateValue}=useFormDate();
    console.log("dateValue:", dateValue);
    const {
        placeholder,
   
        minDate,
        maxDate,
       
        width,
        label,
    
        disabled,
        required,
        tooltip,
        ActiveWhen,
        InactiveWhen,
        backgroundColor,
        borderRadius,
    }= config;
    
  return (
    <>
    <div style={{ marginBottom: "20px" }}>
            {label && <label>{label}</label>}
            <input

                type="date"
                
            
                min={minDate}
                max={maxDate}
                disabled={disabled}
                required={required}
                title={tooltip}
                onChange={(e) => setDateValue(e.target.value)}
                style={{
                    width: `${width}%`,
                    backgroundColor,
                    borderRadius: `${borderRadius}px`,
                    padding: "8px",
                    border: "1px solid #ccc",
                    cursor: disabled ? 'not-allowed' : 'text',
                    opacity: disabled ? 0.6 : 1,

                }}
            />
        </div>
        </>
  )
}

export default DatePickerPreview