import React, { useEffect,useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import imgemail from "./imgemail.jpg";
import "./EmailVerify.css";
import axios from 'axios';
import { BASE_URL } from '../../utils/utils/constants';
import Notification from '../../services/Notification';




const EmailVerification = () => {
    const [message, setMessage] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    
    
    const verifyEmail = async ()=>{
        const  Token = new URLSearchParams(location.search).get('token');
        console.log("get token from url : ",Token);
         console.log("test url 0", `${BASE_URL}/verify-email?token=${Token}`)
        if(Token){
        console.log("test url 1", `${BASE_URL}/verify-email?token=${Token}`)
            
            try {
                const res = await axios.get(
                  `${BASE_URL}/verify-email?token=${Token}`
                );
                console.log(res.data);
                if(res.data) {
                
                    setMessage('Email verified successfully! Redirecting to login page...');
                    setTimeout(() => {
                        navigate('/auth/userDataEntry'); // Navigate to login after successful verification
                        Notification.success("Success", "Your account has been created successfully.  ");
                    }, 3000);
                } else{
                    setMessage('Invalid token or expired token,please try to signup again ');
                }
                
            } catch (error) {
                console.log(error);
                console.log(error.response);
                setMessage('Email verification failed. Please try again.');
                
            }

        } else{
             setMessage('Invalid verification link. ');
                

        }
               
    }; 

    useEffect(()=>{

        verifyEmail();
        
    },[location,navigate]);

    


  return (
    <div >
        <div className="flex w-full items-center justify-center  bg-gray-50">
            <div className="bg-white p-6 rounded-lg mt-8 shadow-[0_6px_12px_rgba(0,0,0,0.4)] max-w-[1000px] w-full transform transition-all duration-500 ease-in-out hover:shadow-[0_7px_12px_rgba(0,0,0,0.3)]">
            <div className="flex justify-center mb-4">
            <img 
              src={imgemail} 
              alt="Verification" 
              className="w-[500px] h-[450px] object-contain "
            />
          </div>
            <h1 className="text-3xl font-bold text-center mb-8 ">
                Email Verification
              </h1>
                <div className="flex flex-row gap-[10px] w-full items-center justify-center">

                    <p className={`text-center text-lg  ${message.includes('successfully') ? 'text-green-500' : 'text-red-500'} mb-4`}>
                        {message}
                    
                    </p>
                    <div class="loader">
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                    </div>
                </div>
                <div className="flex justify-center">
                  
               </div>
            </div>
        </div>
        
    </div>
  );
};

export default EmailVerification;