// src/components/forms/TextForm.js
import React, { useState, useEffect } from "react";
import TextPreview from "../previews/TextPreview";
import AccordionItem from "./Accordion/Accordion";
import { MdLabel } from "react-icons/md";

// export const getDefaultTextConfig = () => ({
//   content: "Sample Text",
//   fontSize: "16px",
//   color: "#333333",
//   alignment: "left",
//   fontWeight: "normal",
// });
export const getDefaultTextConfig = () => ({
  content: "",
  fontSize: "",
  color: "",
  alignment: "",
  fontFamily: "",
  fontWeight: "",
  textDecoration: "",
  textTransform: "",
  fontStyle: "",
  fontFamily: "",
  textDecoration: "",
});
const TextForm = ({ config, onConfigChange }) => {
  const [textAlign, setTextAlign] = useState(config.alignment || "left");
  const [fontSize, setFontSize] = useState(config.fontSize.replace("px", ""));
  const [fontWeight, setFontWeight] = useState(config.fontWeight || "normal");
  const [fontStyle, setFontStyle] = useState(config.fontStyle || "normal");
  const [fontFamily, setFontFamily] = useState(config.fontFamily || "normal");
  const [textTransform, setTextTransform] = useState(config.textTransform);
  const [textDecoration, setTextDecoration] = useState(config.decoration || "normal");
  const [fonts, setFonts] = useState([
    // Serif Fonts
    "Times New Roman, serif",
    "Georgia, serif",
    "Garamond, serif",
    "Baskerville, serif",
    "Palatino, serif",
    "Bookman, serif",
    "Merriweather, serif",
    "Playfair Display, serif",
    "Libre Baskerville, serif",
    "PT Serif, serif",

    // Sans-Serif Fonts
    "Arial, sans-serif",
    "Helvetica, sans-serif",
    "Verdana, sans-serif",
    "Tahoma, sans-serif",
    "Futura, sans-serif",
    "Gill Sans, sans-serif",
    "Open Sans, sans-serif",
    "Roboto, sans-serif",
    "Lato, sans-serif",
    "Montserrat, sans-serif",

    // Monospace Fonts
    "Courier New, monospace",
    "Consolas, monospace",
    "Lucida Console, monospace",
    "Monaco, monospace",
    "Source Code Pro, monospace",
    "Inconsolata, monospace",
    "Fira Code, monospace",
    "JetBrains Mono, monospace",
    "IBM Plex Mono, monospace",
    "Ubuntu Mono, monospace",

    // Display Fonts
    "Oswald, sans-serif",
    "Lobster, cursive",
    "Pacifico, cursive",
    "Raleway, sans-serif",
    "Anton, sans-serif",
    "Abril Fatface, cursive",
    "Play, sans-serif",
    "Permanent Marker, cursive",
    "Fredoka One, cursive",
    "Bangers, cursive",

    // Handwriting Fonts
    "Comic Sans MS, cursive",
    "Dancing Script, cursive",
    "Great Vibes, cursive",
    "Satisfy, cursive",
    "Sacramento, cursive",
    "Gloria Hallelujah, cursive",
    "Amatic SC, cursive",
    "Indie Flower, cursive",
    "Architects Daughter, cursive",
    "Shadows Into Light, cursive",

    // Additional Fonts
    "Poppins, sans-serif",
    "Nunito, sans-serif",
    "Righteous, cursive",
    "Exo, sans-serif",
    "Zilla Slab, serif",
    "Quicksand, sans-serif",
    "Cormorant Garamond, serif",
    "Baloo, cursive",
    "Noto Sans, sans-serif",
    "PT Sans, sans-serif",
  ]
  );

  console.log("fontFamily", fontFamily);
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "fontSize" && value < 0) {
      // Do not update the fontSize if the value is less than 0
      return;
    }
    if (name === "fontSize") {
      // Prevent negative font sizes
      if (value < 0) return;
      setFontSize(value);
      onConfigChange({ ...config, [name]: `${value}px` });
    } else {
      onConfigChange({ ...config, [name]: value });
    }
  };

  return (
    <div className="space-y-4">
      <h3 className="text-xl font-bold mb-4">Text Settings</h3>
      <AccordionItem Title={<><MdLabel /> General Settings</>} panelName="Basic" >

        <div className="mb-4">
          <label className="font-semibold text-lg block mb-1">Content</label>
          <input
            type="text"
            name="content"
            className="w-full border rounded-md px-2 py-1 text-gray-600"
            placeholder="Enter content"
            value={config.content}
            onChange={handleChange}
          />
        </div>

        <div className="mb-4">
          <label className="font-semibold text-lg block mb-1">Font Size</label>
          <input
            type="number"
            name="fontSize"
            value={fontSize}
            onChange={handleChange}
            className="w-full border rounded-md px-2 py-1 text-gray-600"
          />
        </div>
      </AccordionItem>

      <AccordionItem Title={<><MdLabel />Styling Settings</>} panelName="Basic">
        <div className="mb-4 flex items-center space-x-6">
          {/* Font Color */}
          <div className="flex items-center">
            <label className="font-semibold mr-2">Font Color</label>
            <input
              type="color"
              name="color"
              value={config.color}
              onChange={handleChange}
              className="w-10 h-10 rounded-full border-2 border-gray-300"
            />
          </div>
        </div>


        <div className="mb-4">
          <div className="flex flex-row items-center justify-left space-x-4">
            <label className="font-semibold">Font Weight</label>
            <div className="flex flex-row space-x-2">
              {["normal", "bold", "lighter"].map((weight) => (
                <button
                  key={weight}
                  //  onClick={() => handleFontWeightChange(weight)}
                  onClick={() => {
                    setFontWeight(weight); // Update the text alignment state
                    // Pass the selected alignment to handleChange to update the config
                    handleChange({
                      target: { name: "fontWeight", value: weight },
                    });
                  }}
                  className={`w-10 h-10 flex items-center justify-center border-2 rounded-md ${fontWeight === weight ? "bg-blue-200" : "bg-green"
                    }`}
                >
                  {/* weight icons */}
                  {weight === "normal" && "N"}
                  {weight === "bold" && "B"}
                  {weight === "lighter" && "L"}
                </button>
              ))}
            </div>
          </div>
        </div>

        <div className="mb-4">
          <div className="flex flex-row items-center justify-left space-x-4">
            <label className="font-semibold">Font Styles</label>
            <div className="flex flex-row space-x-2">
              {["normal", "italic", "oblique"].map((style) => (
                <button
                  key={style}
                  onClick={() => {
                    setFontStyle(style); // Update the font styles state
                    // Pass the selected alignment to handleChange to update the config
                    handleChange({
                      target: { name: "fontStyle", value: style },
                    });
                  }}
                  className={`w-10 h-10 flex items-center justify-center border-2 rounded-md ${fontStyle === style ? "bg-blue-200" : "bg-green"
                    }`}
                >
                  {/* style icons */}
                  {style === "normal" && "N"}
                  {style === "italic" && "I"}
                  {style === "oblique" && "O"}
                </button>
              ))}
            </div>
          </div>
        </div>

        <div className="mb-4">
          <div className="flex flex-row items-center justify-left space-x-4">
            <label className="block font-semibold mb-2">Alignment</label>
            <div className="flex space-x-2">
              {["left", "center", "right"].map((align) => (
                <button
                  key={align}
                  onClick={() => {
                    setTextAlign(align); // Update the text alignment state
                    // Pass the selected alignment to handleChange to update the config
                    handleChange({
                      target: { name: "alignment", value: align },
                    });
                  }}
                  className={`w-10 h-10 flex items-center justify-center border-2 rounded-md ${textAlign === align ? "bg-blue-200" : "bg-green"
                    }`}
                >
                  {/* Alignment icons */}
                  {align === "left" && "L"}
                  {align === "center" && "C"}
                  {align === "right" && "R"}
                </button>
              ))}
            </div>
          </div>
        </div>
      </AccordionItem>

      <AccordionItem Title={<><MdLabel />Font & Text Settings</>} panelName="Basic">
        <div className="mb-4">
          <div className="flex flex-col space-y-2">
            <label className="font-semibold">Font Family</label>
            <select
              name="fontFamily"
              value={fontFamily}
              onChange={(e) => {
                const selectedFamily = e.target.value;
                setFontFamily(selectedFamily); // Update the font styles state
                handleChange({
                  target: { name: "fontFamily", value: selectedFamily }, // Pass the selected value to handleChange
                });
              }}
              className="w-full border rounded-md px-3 py-2 text-gray-600"
            >
              <option value="">Select Font</option>
              {fonts?.map((font) => (
                <option key={font} value={font}>
                  {font}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="mb-4">
          <div className="flex flex-col space-y-2">
            <label className="font-semibold">Text Transform</label>
            <select
              name="textTransform"
              value={textTransform}
              onChange={(e) => {
                const selectedTransform = e.target.value;
                setTextTransform(selectedTransform); // Update the font styles state
                handleChange({
                  target: { name: "textTransform", value: selectedTransform }, // Pass the selected value to handleChange
                });
              }}
              className="w-full border rounded-md px-3 py-2 text-gray-600"
            >
              <option value="">Select Transform</option>
              <option value="none">None</option>
              <option value="uppercase">Uppercase</option>
              <option value="lowercase">Lowercase</option>
              <option value="capitalize">Capitalize</option>
            </select>
          </div>
        </div>

        <div className="mb-4">
          <div className="flex flex-col space-y-2">
            <label className="font-semibold">Text Decoration</label>
            <select
              name="textDecoration"
              value={textDecoration}
              onChange={(e) => {
                const selectedDecoration = e.target.value;
                setTextDecoration(selectedDecoration); // Update the font styles state
                handleChange({
                  target: { name: "textDecoration", value: selectedDecoration }, // Pass the selected value to handleChange
                });
              }}
              className="w-full border rounded-md px-3 py-2 text-gray-600"
            >
              <option value="">Select Decoration</option>
              <option value="none">None</option>
              <option value="underline">Underline</option>
              <option value="line-through">Line-through</option>
              <option value="overline">Overline</option>
            </select>
          </div>
        </div>
      </AccordionItem>

    </div>
  );
};

export default TextForm;
export { TextPreview };