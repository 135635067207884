import React from "react";
import SwitchPreview from "../previews/SwitchPreview";

export const getDefaultSwitchConfig = () => ({
  switches: [
    {
      label: "Switch 1",
      checked: false,
      disabled: false,
      size: "medium",
      activeColor: "#4caf50",
      inactiveColor: "#ccc",
      tooltip: "",
      labelPosition: "before",
    },
  ],
});

const SwitchForm = ({ config, onConfigChange }) => {
  const handleSwitchChange = (index, key, value) => {
    const updatedSwitches = [...config.switches];
    updatedSwitches[index][key] = value;
    onConfigChange({ ...config, switches: updatedSwitches });
  };

  const addSwitch = () => {
    const newSwitch = {
      label: `Switch ${config.switches.length + 1}`,
      checked: false,
      disabled: false,
      size: "medium",
      activeColor: "#4caf50",
      inactiveColor: "#ccc",
      tooltip: "",
      labelPosition: "before",
    };
    onConfigChange({ ...config, switches: [...config.switches, newSwitch] });
  };

  const removeSwitch = (index) => {
    const updatedSwitches = config.switches.filter((_, i) => i !== index);
    onConfigChange({ ...config, switches: updatedSwitches });
  };

  return (
    <div>
      <h3>Switch Settings</h3>
      {config.switches.map((switchConfig, index) => (
        <div key={index} style={{ borderBottom: "1px solid #ccc", marginBottom: "10px", paddingBottom: "10px" }}>
          <h4>Switch {index + 1}</h4>

          <label>
            Label:
            <input
              type="text"
              value={switchConfig.label}
              onChange={(e) => handleSwitchChange(index, "label", e.target.value)}
            />
          </label>
          <br />

          <label>
            Checked/Unchecked:
            <input
              type="checkbox"
              checked={switchConfig.checked}
              onChange={(e) => handleSwitchChange(index, "checked", e.target.checked)}
            />
          </label>
          <br />

          <label>
            Disabled:
            <input
              type="checkbox"
              checked={switchConfig.disabled}
              onChange={(e) => handleSwitchChange(index, "disabled", e.target.checked)}
            />
          </label>
          <br />

          <label>
            Size:
            <select
              value={switchConfig.size}
              onChange={(e) => handleSwitchChange(index, "size", e.target.value)}
            >
              <option value="small">Small</option>
              <option value="medium">Medium</option>
              <option value="large">Large</option>
            </select>
          </label>
          <br />

          <label>
            Active Color:
            <input
              type="color"
              value={switchConfig.activeColor}
              onChange={(e) => handleSwitchChange(index, "activeColor", e.target.value)}
            />
          </label>
          <br />

          <label>
            Inactive Color:
            <input
              type="color"
              value={switchConfig.inactiveColor}
              onChange={(e) => handleSwitchChange(index, "inactiveColor", e.target.value)}
            />
          </label>
          <br />

          <label>
            Tooltip Text:
            <input
              type="text"
              value={switchConfig.tooltip}
              onChange={(e) => handleSwitchChange(index, "tooltip", e.target.value)}
            />
          </label>
          <br />

          <label>
            Label Position:
            <select
              value={switchConfig.labelPosition}
              onChange={(e) => handleSwitchChange(index, "labelPosition", e.target.value)}
            >
              <option value="before">Before</option>
              <option value="after">After</option>
            </select>
          </label>
          <br />

          <button type="button" onClick={() => removeSwitch(index)} style={{ marginTop: "10px", backgroundColor: "red", color: "#fff", border: "none", padding: "5px 10px" }}>
            Remove Switch
          </button>
        </div>
      ))}

      <button type="button" onClick={addSwitch} style={{ marginTop: "10px", backgroundColor: "#4caf50", color: "#fff", border: "none", padding: "10px 20px" }}>
        Add Switch
      </button>
    </div>
  );
};

export default SwitchForm;
export {SwitchPreview}