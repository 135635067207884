import {create} from 'zustand';

const useFormDate = create((set) => ({
  dateValue: '',
  dateTimeValue: '',
  timeValue: '',
  setDateValue: (newDate) => set({ dateValue: newDate }),
  setTimeValue: (newTime) => set({ timeValue: newTime }), 
  setDateTimeValue: (newTime) => set({ dateTimeValue: newTime }),  
}));

export default useFormDate;
