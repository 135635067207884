import React from "react";
import { FaCaretDown, FaCog, FaPalette } from "react-icons/fa";
import { MdLabel } from "react-icons/md";
import AccordionItem, { Accordion } from "./Accordion/Accordion";

export const getDefaultColorPickerConfig = () => ({
  label: "Pick Your Color:",
  width: "60",
  height: "60",
  ActiveWhen: false,
  InactiveWhen: false,
});

const ColorPickerForm = ({ config, onConfigChange }) => {
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    onConfigChange((prevConfig) => ({
      ...prevConfig,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  return (
    <div>
    <Accordion>
        <AccordionItem Title={<><MdLabel /> Basic Settings</>} panelName="Basic">
      <label>
        Label:
        <input
          type="text"
          name="label"
          value={config.label}
          onChange={handleChange}
        />
      </label>
      <label>
        Width:
        <input
          type="text"
          name="width"
          value={config.width}
          onChange={handleChange}
        />
      </label>
      <label>
        Height:
        <input
          type="text"
          name="height"
          value={config.height}
          onChange={handleChange}
        />
      </label>
      </AccordionItem>




         <AccordionItem Title={<><FaCog /> Advanced Settings</>} panelName="Advance">

      <label>
        Active When:
        <input
          type="checkbox"
          name="ActiveWhen"
          checked={config.ActiveWhen}
          onChange={handleChange}
        />
      </label>
      <label>
        Inactive When:
        <input
          type="checkbox"
          name="InactiveWhen"
          checked={config.InactiveWhen}
          onChange={handleChange}
        />
      </label>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default ColorPickerForm;
