import React from 'react'
import { Slider } from 'antd';



const RangeSliderPreview = ({config}) => {
    const onChange = (value) => {
        console.log('onChange: ', value);
    };
    // const defaultValue = [1, 3];
    const onChangeComplete = (value) => {
        console.log('onChangeComplete: ', value);
    };
    
    const { name, minValue, maxValue, step, width} = config;
    // console.log('defaultValue preview: ', defaultVal);
    const defaultValue = [2,3];
    console.log('defaultValue preview: ', defaultValue);
    return (
        <div>
        {name && <label>{name}</label>}
          <div className="flex flex-col ">
            <Slider
                range
                keyboard
                min={minValue}
                max={maxValue}
                step={step}
                style={{ width: `${width}%` }}
                
                defaultValue={defaultValue}
                onChange={onChange}
                onChangeComplete={onChangeComplete}
            />
            </div>

        </div>
    )
}

export default RangeSliderPreview