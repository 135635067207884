import React, { useState } from "react";
import { FaCaretDown, FaCog, FaPalette } from "react-icons/fa";
import { RiAddCircleLine } from "react-icons/ri";
import { MdLabel } from "react-icons/md";
import AccordionItem, { Accordion } from "./Accordion/Accordion";
import { RiDeleteBin6Line } from "react-icons/ri";
import DropdownSelect from "./Accordion/DropdownSelect";
import { Button } from "antd";

export const getDefaultMultiselectConfig = () => ({
  label: "Select Option",
  width: "30",
  disabled: false,
  required: false,
  activeWhen: false,
  inactiveWhen: false,
  options: [],
});

const MultiselectForm = ({ config, onConfigChange }) => {
  const [selectVal, setSelectVal] = useState("----Select Mode----");
  const [label, setLabel] = useState("");

  const handleSelect = (e) => {
    setSelectVal(e.target.value);
  };

  const handleUpdateOption = () => {
    if (label) {
      const value = label.toLowerCase().replace(/\s+/g, "-");
      onConfigChange({
        ...config,
        options: [...config.options, { value, label }],
      });
      setLabel("");
    }
  };

  const handleDeleteOption = (valueToDelete) => {
    const updatedOptions = config.options.filter(
      (option) => option.value !== valueToDelete
    );
    onConfigChange({
      ...config,
      options: updatedOptions,
    });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    onConfigChange((prevConfig) => ({
      ...prevConfig,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const renderSelectOptions = () => {
    if (selectVal === "Manually") {
      return (
        <div className="p-6 max-w-lg mx-auto bg-white rounded-lg shadow-lg">
          <label className="block text-lg font-semibold text-gray-700 mb-4">
            Enter your options:
            <input
              type="text"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
              className="mt-2 w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              placeholder="Type an option"
            />
            <button
              onClick={handleUpdateOption}
               className="flex items-center mt-4 w-auto bg-gradient-to-r from-indigo-500 to-purple-500 text-white font-semibold py-2 px-4 rounded-lg shadow-lg hover:shadow-xl hover:from-indigo-600 hover:to-purple-600  active:scale-95 active:bg-gradient-to-r active:from-indigo-700 active:to-purple-700"

            >
           
              <RiAddCircleLine size={23} className="mr-2" /> Add
             
            </button>
          </label>

          <div className="mt-6">
            <h4 className="text-xl font-semibold text-gray-800 mb-2">
              Added Options:
            </h4>
            <div className="space-y-2">
              {config.options.map((option) => (
                <div
                  key={option.value}
                  className="flex justify-between items-center bg-gray-100 p-2 rounded-lg shadow-md hover:bg-gray-200 hover:shadow-lg transition"
                >
                  <span className="text-gray-900 text-md">{option.label}</span>
                  <button
                    onClick={() => handleDeleteOption(option.value)}
                    className="text-red-600 hover:bg-red-200  bg-transparent p-2  active:bg-red-200 active:scale-90 active:text-red-600  rounded-md transition "
                  >
                    <RiDeleteBin6Line size={20} />
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    } else if (selectVal === "Take it from table") {
      return (
        <div>
          <DropdownSelect config={config} onConfigChange={onConfigChange}/>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <div>
        <Accordion>
          <AccordionItem
            Title={
              <div>
                <MdLabel /> Basic Settings
              </div>
            }
            panelName="Basic"
          >
            <label>
              Label:
              <input
                type="text"
                name="label"
                value={config.label}
                onChange={handleChange}
              />
            </label>

            <label>
              Placeholder:
              <input
                type="text"
                name="placeholder"
                value={config.placeholder}
                onChange={handleChange}
              />
            </label>
            <div>
              <label>
                Select options from:
                <select onChange={handleSelect} value={selectVal}>
                  <option disabled>----Select Mode----</option>
                  <option>Manually</option>
                  <option>Take it from table</option>
                </select>
              </label>
              {renderSelectOptions()}
            </div>
          </AccordionItem>

          <AccordionItem
            Title={
              <div>
                <FaCog /> Advanced Settings
              </div>
            }
            panelName="Advance"
          >
            <label>
              Width of the field:
              <input
                type="text"
                name="width"
                value={config.width}
                onChange={handleChange}
              />
            </label>

            <label>
              Active When:
              <input
                type="checkbox"
                name="activeWhen"
                checked={config.activeWhen}
                onChange={handleChange}
              />
            </label>
            <label>
              Inactive When:
              <input
                type="checkbox"
                name="inactiveWhen"
                checked={config.inactiveWhen}
                onChange={handleChange}
              />
            </label>
          </AccordionItem>
        </Accordion>
      </div>
    </>
  );
};

export default MultiselectForm;
