// src/components/forms/DropdownForm.js
import React from "react";
import DropdownPreview from "../previews/DropdownPreview";

export const getDefaultDropdownConfig = () => ({
  options: ["Option 1", "Option 2", "Option 3"],
  selectedOption: "Option 1",
});

const DropdownForm = ({ config, onConfigChange }) => {
  const handleChange = (e) => { 
    const { name, value } = e.target;
    onConfigChange({ ...config, [name]: value });
  }; 
  return (
    // <div>
    //   <h3>Dropdown Settings</h3>
    //   <label>
    //     Options (comma-separated):
    //     <input
    //       type="text"
    //       name="options"
    //       value={config.options.join(", ")}
    //       onChange={(e) =>
    //         onConfigChange({
    //           ...config,
    //           options: e.target.value.split(",").map((opt) => opt.trim()),
    //         })
    //       }
    //     />
    //   </label>
    //   <label>
    //     Selected Option:
    //     <select
    //       name="selectedOption"
    //       value={config.selectedOption}
    //       onChange={handleChange}
    //     >
    //       {config.options.map((option, index) => (
    //         <option key={index} value={option}>
    //           {option}
    //         </option>
    //       ))}
    //     </select>
    //   </label>
    // </div>
    <div className="space-y-4">
      <h3 className="text-xl font-bold mb-2">Dropdown Settings</h3>
      <div className="mb-4">
        <label className="font-semibold text-lg block mb-1">Label</label>
        <input
          type="text"
          name="label"
          className="w-full border rounded-md px-2 py-1 text-gray-600"
          placeholder="Enter label"
          // value={config.content}
          // onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default DropdownForm;
export { DropdownPreview };
