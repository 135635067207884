import React from "react";
import CheckboxPreview from "../previews/CheckboxPreview";

export const getDefaultCheckboxConfig = () => ({
  checkboxes: [
    {
      id: 1,
      label: "Check me",
      checked: false,
      disabled: false,
      textColor: "#000000",
      backgroundColor: "#ffffff",
      borderColor: "#cccccc",
      borderRadius: "5px",
      padding: "5px",
      margin: "10px 0",
    },
  ],
});

const CheckboxForm = ({ config, onConfigChange }) => {
  const handleCheckboxChange = (id, field, value) => {
    const updatedCheckboxes = config.checkboxes.map((checkbox) =>
      checkbox.id === id ? { ...checkbox, [field]: value } : checkbox
    );
    onConfigChange({ ...config, checkboxes: updatedCheckboxes });
  };

  const handleAddCheckbox = () => {
    const newCheckbox = {
      id: Date.now(),
      label: `Checkbox ${config.checkboxes.length + 1}`,
      checked: false,
      disabled: false,
      textColor: "#000000",
      backgroundColor: "#ffffff",
      borderColor: "#cccccc",
      borderRadius: "5px",
      padding: "5px",
      margin: "10px 0",
    };
    onConfigChange({ ...config, checkboxes: [...config.checkboxes, newCheckbox] });
  };

  const handleRemoveCheckbox = (id) => {
    const updatedCheckboxes = config.checkboxes.filter(
      (checkbox) => checkbox.id !== id
    );
    onConfigChange({ ...config, checkboxes: updatedCheckboxes });
  };

  return (
    <div>
      <h3>Checkbox Settings</h3>
      {config.checkboxes.map((checkbox) => (
        <div
          key={checkbox.id}
          style={{
            marginBottom: "10px",
            border: "1px solid #ccc",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          <label>
            Label:
            <input
              type="text"
              value={checkbox.label}
              onChange={(e) =>
                handleCheckboxChange(checkbox.id, "label", e.target.value)
              }
            />
          </label>
          <label style={{ marginLeft: "10px" }}>
            Checked:
            <input
              type="checkbox"
              checked={checkbox.checked}
              onChange={(e) =>
                handleCheckboxChange(checkbox.id, "checked", e.target.checked)
              }
            />
          </label>
          <label style={{ marginLeft: "10px" }}>
            Disabled:
            <input
              type="checkbox"
              checked={checkbox.disabled}
              onChange={(e) =>
                handleCheckboxChange(checkbox.id, "disabled", e.target.checked)
              }
            />
          </label>
          <br />
          <label>
            Text Color:
            <input
              type="color"
              value={checkbox.textColor}
              onChange={(e) =>
                handleCheckboxChange(checkbox.id, "textColor", e.target.value)
              }
            />
          </label>
          <label style={{ marginLeft: "10px" }}>
            Background Color:
            <input
              type="color"
              value={checkbox.backgroundColor}
              onChange={(e) =>
                handleCheckboxChange(checkbox.id, "backgroundColor", e.target.value)
              }
            />
          </label>
          <label style={{ marginLeft: "10px" }}>
            Border Color:
            <input
              type="color"
              value={checkbox.borderColor}
              onChange={(e) =>
                handleCheckboxChange(checkbox.id, "borderColor", e.target.value)
              }
            />
          </label>
          <br />
          <label>
            Border Radius:
            <input
              type="text"
              value={checkbox.borderRadius}
              placeholder="e.g., 5px"
              onChange={(e) =>
                handleCheckboxChange(checkbox.id, "borderRadius", e.target.value)
              }
            />
          </label>
          <label style={{ marginLeft: "10px" }}>
            Padding:
            <input
              type="text"
              value={checkbox.padding}
              placeholder="e.g., 5px"
              onChange={(e) =>
                handleCheckboxChange(checkbox.id, "padding", e.target.value)
              }
            />
          </label>
          <label style={{ marginLeft: "10px" }}>
            Margin:
            <input
              type="text"
              value={checkbox.margin}
              placeholder="e.g., 10px 0"
              onChange={(e) =>
                handleCheckboxChange(checkbox.id, "margin", e.target.value)
              }
            />
          </label>
          <button
            style={{
              marginLeft: "10px",
              backgroundColor: "red",
              color: "white",
              border: "none",
              padding: "5px",
              cursor: "pointer",
            }}
            onClick={() => handleRemoveCheckbox(checkbox.id)}
          >
            Remove
          </button>
        </div>
      ))}
      <button
        style={{
          marginTop: "10px",
          backgroundColor: "green",
          color: "white",
          border: "none",
          padding: "10px",
          cursor: "pointer",
        }}
        onClick={handleAddCheckbox}
      >
        Add Checkbox
      </button>
    </div>
  );
};

export default CheckboxForm;
export { CheckboxPreview };
