import React from "react";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import Main from "./components/main/Main";
import Header from "./components/header/Header";
import HomePage from "./pages/homepage/Homepage";
import MainLayout from "./layouts/MainLayout";
import AuthLayout from "./layouts/AuthLayout";
import WelcomePage from "./pages/WelcomePage";
import Quiz from "./components/questionnaireSection/Quiz";
import UserProfile from "./components/userProfile/UserProfile";
import Preview from "./components/preview/Preview";
import ProtectedRoutes from "./components/Signup/ProtectedRoutes";
import useHeaderStore from "./store/useHeaderStore";
import useFormStore from "./store/useFormStore";
import useFooterStore from "./store/useFooterStore";

import EmailVerification from "./components/Signup/EmailVerification";
// import FormConfiguration from "./pages/formbuilder/FormConfiguration";
import FormConfiguration from "./components/Admin/formBuilder/FormConfiguration";
import PaymentMethods from "./pages/payments/Payments";
import Checkout from "./pages/checkout/Checkout";
import NavigationConfig from "./components/header/NavigationConfig";
import HeadMain from "./components/header/HeadMain";
import { Link } from "react-router-dom";
//// rounnit
//import OnboardingForm from "./components/Onboarding/OnboardingForm";
//import Link1 from "./components/HeaderTemplates/Link1";
//import Link2 from "./components/HeaderTemplates/Link2";
//import HeaderTemplate3 from "./components/HeaderTemplates/HeaderTemplate3";

// new imports
import {
  Welcome,
  ComponentsConfig,
  ComponentSelectionForm, //Abhishek
  HeaderConfig,
  FooterConfig,
  Login,
  OnboardingQuestions,
  PageConfig,
  Register,
} from "./pages";

import {
  AppPreview,
  HeaderPreview,
  FooterPreview,
  ComponentPreview,
} from "./pages/preview";
import Page from "./pages/configure/Page";
import ImageUpload from "./components/Admin/Admin/Assets/ManageImages";
// import VideoUpload from "./components/admin/assets/VideoUpload";
// import AudioUpload from "./components/admin/assets/AudioUpload";

import OnboardingForm from "./components/Onboarding/OnboardingForm";
import Link1 from "./components/HeaderTemplates/Link1";
import Link2 from "./components/HeaderTemplates/Link2";
import RenderPage from "./pages/configure/RenderPage";
import ComponentConfigPage from "./configcomponents/ComponentConfigPage";
import ComponentViewerPage from "./configcomponents/ComponentViewerPage";
import "../src/configcomponents/Form.css";
// Define a component that includes the Header and an Outlet for nested routes
const LayoutWithHeader = () => (
  <div>
    <Header />
    <Outlet /> {/* This Outlet will render the child routes */}
  </div>
);

const WelcomeLayout = ({ children }) => {
  return (
    <main>
      {children}
      {/* <WelcomeButtons /> */}
    </main>
  );
};

const App = () => {
  const { getHeaderConfiguration } = useHeaderStore();
  const { getFormConfiguration } = useFormStore();
  const { getFooterConfiguration } = useFooterStore();

  const appRouter = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <Header />
          <HomePage />
        </>
      ),
    },
    {
      path: "/config-steps",
      element: (
        <>
          <Header />
          <ProtectedRoutes>
            <WelcomeLayout>
              <Welcome />
            </WelcomeLayout>
          </ProtectedRoutes>
        </>
      ), // Use the new LayoutWithHeader here
      children: [
        {
          path: "welcome",
          index: true,
          element: <Quiz />,
        },
        {
          path: "onboarding",
          element: <OnboardingForm />,
        },
        {
          path: "header",
          element: <ComponentConfigPage />,
          // element: <HeaderConfig />,
        },
        {
          path: "view",
          element: <ComponentViewerPage />,
          // element: <HeaderConfig />,
        },
        // {
        //   path: "footer",
        //   element: <FooterConfig />,
        // },
        // {
        //   path: "form",
        //   element: <FormConfiguration />,
        // },
        // {
        //   path: "components",
        //   element: <ComponentsConfig />,
        // },
        // {
        //   path: "components",
        //   element: <ComponentSelectionForm />, // Abhishek
        // },
        // {
        //   path: "page",
        //   element: <Page />,
        // },
        // {
        //   path: "styles",
        //   element: (
        //     <div className="flex mt-40 text-color-white bg-color-primary-light  min-h-[73.7vh] ">
        //       <h1 className="styles-header text-xl text-center">
        //         your styling will be updated here
        //       </h1>
        //     </div>
        //   ),
        // },
        // {
        //   path: "assets",
        //   element: <ImageUpload />,
        // },
      ],
    },
    // route renders an app
    {
      // path: `${localStorage.getItem("appUrl")}/home`,
      path: "app/:appName/:appId",
      element: <AppPreview />,
      loader: async () => {
        const headerConfig = await getHeaderConfiguration();
        const formConfig = await getFormConfiguration();
        const footerConfig = await getFooterConfiguration();
        return { headerConfig, formConfig, footerConfig };
      },
      errorElement: (
        <div>
          <h4>You have not configured anything yet.</h4>
          <Link to={"/welcome"}>click here to configure your app</Link>
        </div>
      ),
    },
    // this is a function to call header configuration details from backend or mock api from json db
    // route to configure a form page here ~Tim
    { path: "/configure/form/:page", element: <FormConfiguration /> },
    { path: "/configure/payments", element: <PaymentMethods /> },
    { path: "/checkout", element: <Checkout /> },
    { path: "/configure/navlinks", element: <NavigationConfig /> },
    // rounnits code here
    { path: "/configure/header/:id", element: <HeadMain /> },
    // {path:"/preview/header/",element:<HeadMain/>},

    { path: "/dashboard", element: <div>USER dashboard</div> },
    {
      path: "/admin/*",
      element: (
        <>
          <Header />
          <ProtectedRoutes>
            <MainLayout />
          </ProtectedRoutes>
        </>
      ),
    },
    {
      path: "preview/:appId/:screenId",
      element: <Preview />,
    },
    {
      path: "/auth/*",
      element: <AuthLayout />,
    },
    {
      path: "/welcome",
      element: (
        <ProtectedRoutes>
          {" "}
          <WelcomePage />{" "}
        </ProtectedRoutes>
      ),
    },
    {
      path: "/onboarding",
      element: <OnboardingForm />,
    },
    {
      path: "/header-preview",
      element: <HeaderPreview />,
    },
    {
      path: "/footer-preview",
      element: <FooterPreview />,
    },
    {
      path: "/component-preview",
      element: <ComponentPreview />,
    },
    {
      path: "/header-preview/j124687(page json key)",
      element: <Link1 />,
    },
    {
      path: "/header-preview/j124688(page json key)",
      element: <Link2 />,
    },
    {
      path: "/header-preview/j124687(page json key)",
      element: <Link1 />,
    },
    {
      path: "/header-preview/j124688(page json key)",
      element: <Link2 />,
    },
    {
      path: "/verify-email",
      element: <EmailVerification />,
    },
    {
      path: "/hii",
      element: <RenderPage />,
    },
    {
      path: "/userProfile",
      element: (
        <ProtectedRoutes>
          <UserProfile />
        </ProtectedRoutes>
      ),
    },
  ]);
  return <RouterProvider router={appRouter} />;
};

export default App;
