import React, { useEffect } from "react";
import { FaUserGear } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/newLogo.png";
import Logout from "../Logout/Logout";
import profileStore from "../../store/profileStore";
import { IoSettingsOutline } from "react-icons/io5";
import { LuUserCircle2 } from "react-icons/lu";
import { FiLogOut } from "react-icons/fi";
import { FaUserCircle } from "react-icons/fa";
import { FaCogs } from "react-icons/fa";
import { FaHome } from "react-icons/fa";
import useAuthStore from "../../store/useAuthStore";
import { ProfileIcon, ProfileMenu, ProfileMenuItem } from "./ProfileMenu";

const Header = () => {
  const application_name = localStorage.getItem("application_name");
  const userImage = profileStore((state) => state.userImage);
  const navigate = useNavigate();
  let location = useLocation();
  const { isAuthenticated, login, logout } = useAuthStore();

  useEffect(() => {
    if (
      !isAuthenticated &&
      location.pathname !== "/" &&
      location.pathname !== ""
    ) {
      navigate("/auth/sign", { replace: true });
    }
  }, [location.pathname, navigate, isAuthenticated]);

  return (
    <>
      <header className="sticky top-0 z-50 bg-color-primary-dark border-color-gray border-b text-white  h-21 max-h-22">
        <nav
          className={` flex ${
            !location.pathname.includes("/admin") &&
            !location.pathname.includes("/userprofile")
              ? "justify-between"
              : "justify-between items-center"
          } items-center`}
        >
          <div className={`h-25 w-33 py-2 ml-2 cursor-pointer`}>
            <img className="h-20 w-33 mt-[-10px] " src={logo} alt="Logo" />
          </div>
          {location.pathname !== "/" && (
          <div style={{ textAlign: "center" }}>
            <p className="text-lg font-large text-white">
              <span className="font-semibold">
                {application_name
                  ? application_name
                  : "Your app name will be here"}
              </span>
            </p>
          </div>
           )}
          <div className="flex items-center mr-12">
            {!location.pathname.includes("/admin") &&
              !location.pathname.includes("/userprofile") &&
              !location.pathname.includes("/config-steps") && (
                <ul className="hidden lg:flex space-x-6">
                  <li>
                    <a
                      href="#home"
                      className="hover:text-color-secondary flex items-center ease-in duration-100"
                    >
                      <FaHome className="text-lg mr-1" />
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      href="#features"
                      className="hover:text-color-secondary ease-in duration-100  flex items-center"
                    >
                      <FaCogs className="text-lg mr-1" />
                      Features
                    </a>
                  </li>

                  <li>
                    <a
                      href="#contact"
                      className="hover:text-color-secondary flex items-center ease-in duration-100"
                    >
                      <FaUserCircle className="text-lg mr-1" />
                      Contact
                    </a>
                  </li>

                  <li>
                    <button
                      onClick={(event) => {
                        event.preventDefault();
                        navigate("/auth/signup");
                      }}
                      className="bg-color-secondary px-8 py-3 ml-2 rounded-md capitalize font-bold hover:opacity-80 ease-in duration-100"
                    >
                      Get Started
                    </button>
                  </li>
                </ul>
              )}

            {(location.pathname.includes("/admin") ||
              location.pathname.includes("/userprofile") ||
              location.pathname.includes("/config-steps")) && (
              <div className="flex  space-x-9">
                <div>
                  <ProfileMenu
                    trigger={
                      <div
                        className="h-11 w-11 cursor-pointer"
                        //onClick={() => navigate("/userprofile")}
                      >
                        <ProfileIcon />
                      </div>
                    }
                  >
                    <ProfileMenuItem>
                      <div
                        className="w-full flex gap-4"
                        onClick={() => navigate("/userprofile")}
                      >
                        <LuUserCircle2 size={25} />
                        Profile
                      </div>
                    </ProfileMenuItem>
                    <ProfileMenuItem>
                      <IoSettingsOutline size={25} /> Setting
                    </ProfileMenuItem>

                    <ProfileMenuItem>
                      <FiLogOut size={23} /> <Logout />
                    </ProfileMenuItem>
                  </ProfileMenu>
                </div>
              </div>
            )}
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
