import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getTokenFromLocalStorage,
  setTokenToLocalStorage,
} from "../../utils/utils/LocalStorageUtils";
import { SignUpUser } from "../../services/services";
import "./SignupForm.css";
import profileStore from "../../store/profileStore";
import { useSignupValidation } from "./useSignupValidation";
import Notification from "../../services/Notification";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import Header from "../header/Header";
import Googlelogin from "../SignIn/Googlelogin";
import SendEmailService from "../../services/SendEmailService";
import { showErrorToast, showSuccessToast } from "../Toast/ToastNotification";
 import useAuthStore from "../../store/useAuthStore";

const SignupForm = () => {
const { isAuthenticated, login, logout } = useAuthStore();

  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [registrationSuccess, setRegistrationSuccess] = useState("");
  const setSignupEmail = profileStore((state) => state.setSignupEmail);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const [emailsent, setEmailsent] = useState(false);
  const [message, setMessage] = useState("");

  const {
    nameError,
    emailError,
    passwordError,
    validateName,
    validateEmail,
    validatePassword,
  } = useSignupValidation();

  useEffect(() => {
    const token = getTokenFromLocalStorage("token");
    if (token) {
    login();
      navigate("/welcome");
    }
  }, [navigate]);

 

  const handleSignUp = async (e) => {
    e.preventDefault();
    const isNameValid = validateName(username);
    const isEmailValid = validateEmail(email);
    const isPasswordValid = validatePassword(password, confirmPassword);

    if (password !== confirmPassword) {
      //      setPasswordError("Password and Confirm Password must match");
      // Notification.error("Error", "Passwords do not match");
      showErrorToast("Passwords do not match");
      return;
    }

    if (isNameValid && isEmailValid && isPasswordValid) {
      try {
        const reqDto = {
          username,
          password,
          email,
          roles: "ADMIN", // You can update this if needed
        };
        console.log("reqDto", JSON.stringify(reqDto, null, 2));

        const response = await SignUpUser(reqDto);
        console.log("response*************", response);
  
        const signupToken = response.data.token;
        console.log("signupToken*************", signupToken);
          await SendEmailService({ verifyToken: signupToken, email });
         console.log('verification  link sent successfully.');
          setMessage("Email has been sent to your inbox. Please verify your email for further registration.");
          setEmailsent(true);
        
         if (response.data) {
             setSignupEmail(email);
         }
          console.log("6", response.data);
          console.log("Success in registering ");


        showSuccessToast("User created successfully. Please verify your email");

        //navigate("/auth/signin");
          // navigate("/auth/userDataEntry");
      } catch (error) {
        // General error check
        console.log("Error 1:", error);

        if (error) {
          if (typeof error !== "string") {
            error = String(error); // Convert error to string
          }
          console.log("3", typeof error);

          if (error && error.includes("Both username and email are already registered.")
          ) {
            console.log("Both username and email are already registered.");
            showErrorToast("Both username and email are already registered.");
          } else if (error && error.includes("Email is already registered")) {
            console.log(
              "Email is already registered. Please use a different email."
            );
            showErrorToast("Email is already registered. Please use a different email.");
          } else if (error && error.includes("Username is already taken.")) {
            console.log(
              "Username is already taken. Please choose a different username."
            );
            showErrorToast("Username is already taken. Please choose a different username.");
          } else {
            console.log("Error occurred:", error);
            showErrorToast("Server Error");
          }
        } else {
          // Something else happened while setting up the request
          // Notification.error(
          //   "Error",
          //   "Unexpected error occurred try after some time "
          // );
          showErrorToast("Unexpected error occurred try after some time");
          console.log("Error occurred:", "Unexpected error ");
        }
        setRegistrationSuccess("");
      }
    }
  };

  return (
    <>
      <div className="w-full h-screen bg-wight-500 flex justify-center items-center">
        <div className="w-full max-w-[700px] bg-[#2c2c2c] rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 border-gray-300">
          <div className="p-7 space-y-8 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold text-center leading-tight tracking-tight text-white md:text-4xl">
              Sign Up
            </h1>
            <form className="space-y-6" onSubmit={handleSignUp}>
              <div>
                <input
                  type="text"
                  id="username"
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value);
                    validateName(e.target.value); // Real-time validation
                  }}
                  placeholder="Full Name"
                  required
                  className={`block w-full h-[50px] px-4 bg-[#3b3b3b] border-b-2 border-[#555] rounded-md text-base text-[#f0f0f0] ${
                    nameError ? "border-b-3 border-red-500" : "border-[#555]"
                  } transition-all ease-in-out duration-300`}
                />
                {nameError && <p className="error-message">{nameError}</p>}
              </div>
              <div>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    validateEmail(e.target.value); // Real-time validation
                  }}
                  placeholder="Email"
                  required
                  className={`block w-full h-[50px] px-4 bg-[#3b3b3b] border-b-2 border-[#555] rounded-md text-base text-[#f0f0f0] ${
                    emailError ? "border-b-3 border-red-500" : "border-[#555]"
                  } transition-all ease-in-out duration-300`}
                />
                {emailError && <p className="error-message">{emailError}</p>}
              </div>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    validatePassword(e.target.value, confirmPassword); // Real-time validation
                  }}
                  required
                  placeholder="Password"
                  className={`block w-full h-[50px] px-4 bg-[#3b3b3b] border-b-2 border-[#555] rounded-md text-base text-[#f0f0f0] ${
                    passwordError
                      ? "border-b-3 border-red-500"
                      : "border-[#555]"
                  } transition-all ease-in-out duration-300`}
                />
                {showPassword ? (
                  <BsFillEyeFill
                    onClick={() => setShowPassword(false)}
                    className="cursor-pointer absolute top-4 right-2 text-gray-400"
                  />
                ) : (
                  <BsFillEyeSlashFill
                    onClick={() => setShowPassword(true)}
                    className="cursor-pointer absolute top-4 right-2 text-gray-400"
                  />
                )}
                {passwordError && (
                  <p className="error-message">{passwordError}</p>
                )}
              </div>
              <div className="relative">
                <input
                  type={showConfPassword ? "text" : "password"}
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    validatePassword(password, e.target.value); // Real-time validation
                  }}
                  placeholder="Confirm Password"
                  required
                  className={`block w-full h-[50px] px-4 bg-[#3b3b3b] border-b-2 border-[#555] rounded-md text-base text-[#f0f0f0] ${
                    passwordError
                      ? "border-b-3 border-red-500"
                      : "border-[#555]"
                  } transition-all ease-in-out duration-300`}
                />
                {showConfPassword ? (
                  <BsFillEyeFill
                    onClick={() => setShowConfPassword(false)}
                    className="cursor-pointer absolute top-4 right-2 text-gray-400"
                  />
                ) : (
                  <BsFillEyeSlashFill
                    onClick={() => setShowConfPassword(true)}
                    className="cursor-pointer absolute top-4 right-2 text-gray-400"
                  />
                )}
              </div>
              <button
                type="submit"
                className="w-full bg-[#1e90ff] text-white mt-7 font-bold text-[1.2rem] py-2.5 px-5 rounded-md cursor-pointer transition-colors duration-300 hover:bg-[#1c86ee]"
              >
                Sign Up
              </button>
              {emailsent && 
                  <div className="text-[15px] font-medium text-[#4be737] mt-4 md-8 ">
                    {message}
                    
                  </div>
              }
              
              <p className="text-[15px] font-medium text-[#90cdf4] mt-4">
                Already have an account?{" "}
                <a
                  href="/auth/signin"
                  className="text-[16px] first-line:font-bold text-[#90cdf4] "
                >
                  Sign In
                </a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignupForm;
