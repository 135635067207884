// customInstance.js
import axios from "axios";
export const appUrl = "http://5.78.130.102:8080/api";
const localDb = "http://localhost:3000";
const customInstance = axios.create({
  baseURLone: localDb,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer token here`,
  },
});

customInstance.interceptors.request.use(
  (config) => {
    // You can modify the request config before sending the request (e.g., add tokens)
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

customInstance.interceptors.response.use(
  (response) => {
    // Handle the response (e.g., transform data)
    return response;
  },
  (error) => {
    // Handle response errors
    return Promise.reject(error);
  }
);

export default customInstance;
