import apiURL from "../../config/apiConfig";
export const BASE_URL = `${apiURL}/api`;
console.log("base url",BASE_URL);
console.log("api url",apiURL);


export const SIGNIN_URL =BASE_URL+"/signIn";
export const SIGNUP_URL = BASE_URL+"/signUp";


export const SAVE_RULES_API = "api/blocks";


export const SAVA_DATA_API = BASE_URL + "save-data";

//TABLE APIS
export const CREATE_TABLE_API = BASE_URL + "create-table";
export const GET_TABLE_DATA_API = BASE_URL + "table-data";
export const GET_TABLE_NAMES_API = BASE_URL + "table-names";
export const GET_TABLE_DETAILS_API = BASE_URL + "table-details";


export const UPDATE_DATA_API = BASE_URL + "update-data";
export const DELETE_DATA_API= BASE_URL + "delete-data";
export const ADVANCE_SEARCH_API= BASE_URL + "advance-search";
export const SEARCH_API= BASE_URL + "table-data/searchValue";
