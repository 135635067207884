import React, { useState } from "react";
import { MdLabel } from "react-icons/md";
import AccordionItem, { Accordion } from "./Accordion/Accordion";

export const getDefaultRangeSliderConfig = () => ({
    name: "MySlider",
    minValue: 0,
    maxValue: 5,
    width: 30,
    step: 1,
    
    activeWhen: false, 
    inactiveWhen: false,
});

const RangeSliderForm = ({ config, onConfigChange }) => {
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        
        // If the field is minValue or maxValue, validate that it's a number
        if (name === "minValue" || name === "maxValue") {
          const numericValue = parseFloat(value);
          if (!isNaN(numericValue)) {
            onConfigChange({
              ...config,
              [name]: numericValue,
            });
          }
        } else {
          onConfigChange({
            ...config,
            [name]: type === "checkbox" ? checked : value,
          });
        }
      };
    



 

    // const handleInputChange = (e) => {
    //     const value = e.target.value;
    //     const numberArray = value
    //       .split(",")
    //       .map((num) => parseFloat(num.trim()))
    //       .filter((num) => !isNaN(num)); // Ensure valid numbers
    //     if (numberArray.length <= 2 && value.split(",").length <= 2) {
    //       onConfigChange({ ...config, defaultVal: numberArray });
    //     } else {
    //       alert("Please enter exactly two numbers separated by one comma.");
    //     }
    //   };

   

    return (
        <div>
            <Accordion>
                <AccordionItem
                    Title={
                        <>
                            <MdLabel /> Basic Settings
                        </>
                    }
                    panelName="Basic"
                >
                    <label>
                        Name:
                        <input
                            type="text"
                            name="name"
                            value={config.name}
                            onChange={handleChange}
                        />
                    </label>

                    <label>
                        Width:
                        <input
                            type="number"
                            name="width"
                            value={config.width}
                            onChange={handleChange}
                        />
                    </label>

                    <label>
                        Minimum Value:
                        <input
                        type="number"
                        name="minValue"
                        value={config.minValue}
                        onChange={handleChange}
                        ></input>
                    </label>

                    <label>
                        Maximum Value:
                        <input
                        type="number"
                        name="maxValue"
                        value={config.maxValue}
                        onChange={handleChange}

                        ></input>
                    </label>

                    <label>
                        Step:
                        <input
                            type="number"
                            name="step"
                            value={config.step}
                            onChange={handleChange}
                        />
                    </label>

  
      

                    <label>
                        Active When:
                        <input
                            type="checkbox"
                            name="activeWhen"
                            checked={config.activeWhen}
                            onChange={handleChange}
                        />
                    </label>

                    <label>
                        Inactive When:
                        <input
                            type="checkbox"
                            name="inactiveWhen"
                            checked={config.inactiveWhen}
                            onChange={handleChange}
                        />
                    </label>
                </AccordionItem>
            </Accordion>
        </div>
    );
};

export default RangeSliderForm;
