import React, { useState } from "react";
import { FaPen, FaListAlt, FaArrowsAltH, FaPalette, FaFont } from "react-icons/fa";
import TextAreaPreview from "../previews/TextAreaPreview";
import AccordionItem from "./Accordion/Accordion";
import { MdLabel } from "react-icons/md";

export const getDefaultTextAreaConfig = () => ({
  placeholder: "",
  width: 100,
  height: 100,
  borderColor: "#ccc",
  fontSize: 16,
  backgroundColor: "#fff",
  textColor: "#000",
});

const TextAreaForm = ({ config, onConfigChange }) => {
  
  const handleChange = (e) => {
    const { name, value, type } = e.target;
    const newValue = type === "number" ? parseInt(value, 10) || 0 : value;
    onConfigChange({ ...config, [name]: newValue });
  };

  return (
    <div className="font-sans p-6  rounded-lg shadow-md">
      <h3 className="text-lg font-bold flex items-center gap-2 mb-4 text-gray-700">
        <FaListAlt className="text-blue-500" /> Textarea Settings
      </h3>

      {/* Accordion 1: General Settings */}
        <AccordionItem Title={<><MdLabel /> General Settings</>} panelName="Basic">
        <div className="mb-4">
          <label className="block text-sm font-medium text-black">Placeholder</label>
          <input
            type="text"
            name="placeholder"
            value={config.placeholder}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-blue-300"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-black">Width</label>
          <input
            type="number"
            name="width"
            value={config.width}
            onChange={handleChange}
            min="0"
            className="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-blue-300"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-black">Height</label>
          <input
            type="number"
            name="height"
            value={config.height}
            onChange={handleChange}
            min="0"
            className="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-blue-300"
          />
        </div>
        </AccordionItem>

      {/* Accordion 2: Styling Settings */}
      <AccordionItem Title={<><MdLabel /> Styling Settings</>} panelName="Basic">
        <div className="mb-4">
          <label className="block text-sm font-medium text-black">Font Size</label>
          <input
            type="number"
            name="fontSize"
            value={config.fontSize}
            onChange={handleChange}
            min="0"
            className="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-blue-300"
          />
        </div>
      </AccordionItem>

      {/* Accordion 3: Color Settings */}
      <AccordionItem Title={<><MdLabel /> Color Settings</>} panelName="Basic">
        <div className="mb-4">
          <label className="block text-sm font-medium text-black">Border Color</label>
          <input
            type="color"
            name="borderColor"
            value={config.borderColor}
            onChange={handleChange}
            className="w-full h-10 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-black">Background Color</label>
          <input
            type="color"
            name="backgroundColor"
            value={config.backgroundColor}
            onChange={handleChange}
            className="w-full h-10 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-black">Text Color</label>
          <input
            type="color"
            name="textColor"
            value={config.textColor}
            onChange={handleChange}
            className="w-full h-10 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
          />
        </div>
      </AccordionItem>
    </div>
  );
};

export default TextAreaForm;
export { TextAreaPreview };
