import React, { useState, useRef, useEffect } from "react";
import formComponents from "../components/forms/formComponents";
import { db } from "../firebase/firebaseConfig";
import { createData } from "../environment/commonApi";
import useFormComponentStore from "../store/useFormComponentStrore";
import { collection, getDocs, doc, getDoc, setDoc } from "firebase/firestore";
import NavigationButton from "../components/forms/NavigationButton";
const ComponentConfigPage = () => {
  const { 
    components, 
    res_status, 
    isLoading, 
    fetchComponents, 
    addComponent 
  } = useFormComponentStore();
  
  const [contentName, setContentName] = useState("Sukusula");
  const [fontSize, setFontSize] = useState(20);
  const [fontColor, setFontColor] = useState("#000000");
  const [fontWeight, setFontWeight] = useState("normal");
  const [textAlign, setTextAlign] = useState("left");
  // const [selectedComponent, setSelectedComponent] = useState("Text");
  const [selectedComponent, setSelectedComponent] = useState("");
  const [config, setConfig] = useState(
    formComponents["Text"].getDefaultConfig()
  );
  const [componentName, setComponentName] = useState("");
  const [status, setStatus] = useState("");
  const [userConfiguredComponents, setUserConfiguredComponents] = useState([]);

  const formContainerRef = useRef(null);
  const previewContainerRef = useRef(null);

  // Fetch user-configured components from Firestore on component mount
  // realtime db
//   useEffect(() => {
//   const fetchAndSetComponents = async () => {
//     try {
//       const components = await fetchComponents(); // Ensure this function fetches and maps data
//       setUserConfiguredComponents(components);
//     } catch (error) {
//       console.error("Error fetching components:", error);
//     }
//   };
//   fetchAndSetComponents(); // Fetch and set components on load
  // }, [fetchComponents]);
  useEffect(() => {
    const fetchAndSetComponents = async () => {
      try {
        await fetchComponents(); // This fetches and updates Zustand state with names
        setUserConfiguredComponents(components);
      } catch (error) {
        console.error("Error fetching componens:", error);
      }
    };

    fetchAndSetComponents(); // Call the function on component mount
 }, [fetchComponents]);
  console.log("userConfiguredComponents", userConfiguredComponents);

  // firestore db
  // useEffect(() => {
  //   const fetchUserConfiguredComponents = async () => {
  //     // const querySnapshot = await getDocs(collection(db, "Component"));
  //     const components = querySnapshot.docs.map((doc) => ({
  //       id: doc.id,
  //       ...doc.data(),
  //     }));
  //     setUserConfiguredComponents(components);
  //   };

  //   fetchUserConfiguredComponents();
  // }, []);
//  useEffect(() => {
//    fetchComponents(); // Fetch all components on load
//    setUserConfiguredComponents(components);
//  }, [fetchComponents]);
  
  console.log("components", components);
  // Handle component selection change
  const handleComponentChange = async (e) => {
    const componentName = e.target.value;
    setSelectedComponent(componentName);

    // Check if it's a user-configured component
    const userComponent = userConfiguredComponents.find(
      (c) => c.id === componentName
    );

    if (userComponent) {
      // Load user-configured component from Firestore
      try {
        const docRef = doc(db, "Component", componentName);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setConfig(docSnap.data().json);
          setComponentName(componentName); // Populate name field with the selected component's name
        } else {
          setStatus("No configuration found for the selected component.");
        }
      } catch (error) {
        console.error("Error fetching user-configured component:", error);
        setStatus("Failed to load component configuration.");
      }
    } else {
      // Load the default config for basic/advanced components
      const defaultConfig = formComponents[componentName]?.getDefaultConfig();
      if (defaultConfig) {
        setConfig(defaultConfig);
      } else {
        setStatus("Selected component configuration is not available.");
      }
    }
  };
  const handleConfigChange = (newConfig) => {
    setConfig(newConfig);
  };
  const handleSave = async () => {
    if (!componentName.trim()) {
      setStatus("Please enter a unique name for the component.");
      return;
    }
    const configToSave = { ...config, componentType: selectedComponent };
    console.log("configToSave", configToSave);
    try {
      // Save the main component configuration to Firestore
      await addComponent(componentName, configToSave);
      // Additional save logic if necessary...
      setStatus("Configuration saved successfully!");
    } catch (error) {
      console.error("Error saving configuration:", error);
      setStatus("Failed to save configuration.");
    }
  };

  const syncScroll = () => {
    if (formContainerRef.current && previewContainerRef.current) {
      previewContainerRef.current.scrollTop =
        formContainerRef.current.scrollTop;
    }
  };

  useEffect(() => {
    const formEl = formContainerRef.current;
    formEl?.addEventListener("scroll", syncScroll);
    return () => {
      formEl?.removeEventListener("scroll", syncScroll);
    };
  }, []);

  const SelectedForm = formComponents[selectedComponent]?.form;
  const SelectedPreview = formComponents[selectedComponent]?.preview;
 
  return (
    <div className="flex w-full p-1 bg-gray-200">
      <div className="w-1/4 p-2">
        <div className="p-6 bg-white max-w-md mx-auto shadow-lg rounded-md">
          {/* Title */}
          <div
            ref={formContainerRef}
            className="flex-1 overflow-y-auto pr-4
            scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar scrollbar-thumb-slate-700 scrollbar-track-slate-300
            "
            style={{
              maxHeight: "80vh", // Set the max height for the scrollable area
              scrollbarWidth: "thin", // Thin scrollbar for non-Chromium browsers
            }}
          >
            <h1 className="text-2xl font-bold text-black mb-6">
              Configure Component
            </h1>
            {/* Component Name */}
            <div className="mb-4">
              <label className="font-semibold text-lg block mb-1">
                Component Name
              </label>
              <input
                type="text"
                className="w-full border rounded-md px-2 py-1 text-gray-600"
                placeholder="Enter unique component name"
                value={componentName}
                onChange={(e) => setComponentName(e.target.value)}
              />
            </div>
            {/* Select Component */}
            <div className="mb-4">
              <label className="font-semibold text-lg block mb-1">
                Select Component
              </label>
              <select
                className="w-full border rounded-md px-2 py-1 text-gray-600"
                value={selectedComponent}
                onChange={handleComponentChange}
              >
                <option value="" disabled>
                  Select Component
                </option>
                <optgroup label="Basic & Advanced Components">
                  {Object.keys(formComponents).map((component,index) => (
                    <option key={index} value={component}>
                      {component}
                    </option>
                  ))}
                </optgroup>
                <optgroup label="User-Configured Components">
                  {userConfiguredComponents?.map((userComp,index) => (
                    <option key={index} value={userComp.id}>
                      {userComp.id}
                    </option>
                  ))}
                </optgroup>
              </select>
            </div>
            {SelectedForm && (
              <SelectedForm
                config={config}
                onConfigChange={handleConfigChange}
              />
            )}
            <div className="mt-5 flex flex-col items-center space-y-4">
              <div className="flex flex-row justify-between w-full max-w-md">
                <button
                  onClick={() => setStatus("Preview Updated")}
                  className="px-4 py-2 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
                >
                  Preview
                </button>
                <button
                  onClick={handleSave}
                  className="px-4 py-2 bg-green-500 text-white font-semibold rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400"
                >
                  Save Configuration
                </button>
              </div>
              <p className="text-orange-600 font-medium">{status}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-3/4 p-4 rounded-md border border-gray-500">
        <h2 className="text-2xl font-bold mb-4 text-black text-align-center">
          Preview
        </h2>
        {SelectedPreview && <SelectedPreview config={config} />}
      </div>
    </div>
  );
};

export default ComponentConfigPage;

