// src/components/previews/TextPreview.js
import React from "react";

const TextPreview = ({ config }) => {
  const {
    content,
    fontSize,
    color,
    alignment,
    fontWeight,
    fontStyle,
    fontFamily,
    textTransform,
    textDecoration,
    
  } = config;
   console.log("fontFamily", fontFamily);
  return (
    <div
      style={{
        fontSize: fontSize || "16px",
        color: color || "#333333",
        textAlign: alignment || "left",
        padding: "10px",
        fontWeight: fontWeight || "normal",
        fontStyle: fontStyle || "normal",
        fontFamily: fontFamily || "Arial",
        textTransform: textTransform ,
        textDecoration: textDecoration ,
      }}
    >
      {content || "Sample Text"}
    </div>
  );
};

export default TextPreview;
