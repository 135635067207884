import React, { useState } from "react";
import {  FaCaretDown,  FaCog, FaPalette,  } from "react-icons/fa";
import { MdLabel  } from "react-icons/md";

import "./NumberInput.css";

export const getDefaultNumberInputConfig = () => ({
    placeholder: "Enter number",
    value: "",
    min: 0,
    max: 100,
    step: 1,
    width: "30",
    label: "Number Input",

    disabled: false,
    required: false,
    tooltip: "",
    ActiveWhen:true,
    InactiveWhen:true,
    backgroundColor: "#ffffff",
    borderRadius: "4",
});


const NumberInputForm = ({ config, onConfigChange }) => {
    const [expanded, setExpanded] = useState(null);

    const handleAccordionToggle = (panel) => {
        setExpanded(expanded === panel ? null : panel);
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        onConfigChange({
            ...config,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    return (
        <div className="number-input-form">
            <h3>Number Input Settings</h3>

            {/* Accordion Panels */}
            <div className="accordion">
               
                <div
                    className={`accordion-item ${expanded === "label" ? "expanded" : ""}`}
                    onClick={() => handleAccordionToggle("label")}
                >
                    <div>
                        <h4>
                            <MdLabel/> Basic Settings
                        </h4>
                    </div>
                    <div className="down-arrow"><FaCaretDown/></div>
                </div>
                {expanded === "label" && (
                    <div className="accordion-content">
                        <label>
                            Label:
                            <input
                                type="text"
                                name="label"
                                value={config.label}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            Background Color:
                            <input
                                type="color"
                                name="backgroundColor"
                                value={config.backgroundColor}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            Border Radius:
                            <input
                                type="text"
                                name="borderRadius"
                                value={config.borderRadius}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            Placeholder:
                            <input
                                type="text"
                                name="placeholder"
                                value={config.placeholder}
                                onChange={handleChange}
                            />
                        </label>

                    </div>

                )}

                {/* Appearance Settings */}
                <div
                    className={`accordion-item ${expanded === "appearance" ? "expanded" : ""}`}
                    onClick={() => handleAccordionToggle("appearance")}
                >
                    <div>
                        <h4>
                            <FaPalette/> Advanced Settings
                        </h4>
                    </div>
                    <div className="down-arrow"><FaCaretDown/></div>
                </div>
                {expanded === "appearance" && (
                    <div className="accordion-content">
                        <label>
                            Value:
                            <input
                                type="number"
                                name="value"
                                value={config.value}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            Set a min value:
                            <input
                                type="number"
                                name="min"
                                value={config.min}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            Set a max value:
                            <input
                                type="number"
                                name="max"
                                value={config.max}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            Give Steps:
                            <input
                                type="number"
                                name="step"
                                value={config.step}
                                onChange={handleChange}
                            />
                        </label>


                    </div>
                )}

                {/* Optional Settings */}
                <div
                    className={`accordion-item ${expanded === "inputConfig" ? "expanded" : ""}`}
                    onClick={() => handleAccordionToggle("inputConfig")}
                >
                    <div>
                        <h4>
                            <FaCog/> Optional Settings
                        </h4>
                    </div>

                    <div className="down-arrow"><FaCaretDown/></div>
                </div>
                {expanded === "inputConfig" && (
                    <div className="accordion-content">
                        <label>
                            Hint Box:
                            <input
                                type="text"
                                name="tooltip"
                                value={config.tooltip}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            Width of the field:
                            <input
                                type="text"
                                name="width"
                                value={config.width}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            Disabled the field:
                            <input
                                type="checkbox"
                                name="disabled"
                                checked={config.disabled}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            Make the field mandatory :
                            <input
                                type="checkbox"
                                name="required"
                                checked={config.required}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            Active When:
                            <input
                                type="checkbox"
                                name="activeWhen"
                                checked={config.ActiveWhen}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            Inactive When:
                            <input
                                type="checkbox"
                                name="inactiveWhen"
                                checked={config.InactiveWhen}
                                onChange={handleChange}
                            />
                        </label>


                    </div>
                )}



            </div>
        </div>
    );
};

export default NumberInputForm;
