import React, { useState } from "react";

const ParagraphPreview = ({ config }) => {
  const {
    fontSize,
    lineHeight,
    margin,
    padding,
    color,
    backgroundColor,
    fontWeight,
    textAlign,
    fontFamily,
  } = config;

  // State for the paragraph text
  const [text, setText] = useState(config.text || "");

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  return (
    <div className="p-4">
      <textarea
        value={text}
        onChange={handleTextChange}
        placeholder="Type your paragraph here..."
        rows="4"
        className="w-full mb-4 p-2 border rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500"
        style={{
          fontSize: `${fontSize || 14}px`,
          fontFamily: fontFamily || "Arial",
        }}
      />
      <p
        style={{
          fontSize: `${fontSize || 14}px`,
          lineHeight: lineHeight || 1.5,
          margin: `${margin || 0}px`,
          padding: `${padding || 0}px`,
          color: color || "#333333",
          backgroundColor: backgroundColor || "#ffffff",
          fontWeight: fontWeight || "normal",
          textAlign: textAlign || "left",
          fontFamily: fontFamily || "Arial",
        }}
        className="border p-4 rounded-lg shadow-sm"
      >
        {text || "Your paragraph preview will appear here."}
      </p>
    </div>
  );
};

export default ParagraphPreview;
