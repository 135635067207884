import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import axios from 'axios';

const DropdownSelect = ({ config, onConfigChange }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchTagsFromDB = async () => {
      try {
        const response = await axios.get('api/to get table/name');
        console.log('Fetched tags 1:', response.data);

        const formattedOptions = response.data.map((tag, index) => ({
          value: `${tag.value}-${index}`, // Ensure unique values
          label: tag.label,
        }));
        console.log('Fetched tags 2 :', formattedOptions);


        setOptions(formattedOptions);
      } catch (error) {
        console.error('Error fetching tags:', error);
      }
    };

    fetchTagsFromDB();
  }, []);

  const handleChange = (value) => {
    console.log('Value received:', value);

    const values = Array.isArray(value) ? value : [value];

    const newOptions = values
      .filter((v) => typeof v === "string" && v.trim())
      .map((v,index) => {
                const label = v.trim();
                const value = index+1;
                return { value, label };
              });
      
    if (newOptions.length > 0) {
      const mergedOptions = [...config.options, ...newOptions];
      const uniqueOptions = Array.from(
        new Map(mergedOptions.map((item) => [item.value, item])).values()
      );

      onConfigChange({
        ...config,
        options: uniqueOptions,
      });

      console.log("Updated options:", uniqueOptions);
    } else {
      console.error("No valid string values to process:", value);
    }
  };

  return (
    <div style={{ padding: '20px', maxWidth: '400px' }}>
      <Select
        mode="multiple"
        allowClear
        style={{ width: '100%' }}
        placeholder="Select or add tags"
        onChange={handleChange}
        options={options}
      />
    </div>
  );
};

export default DropdownSelect;
