// src/components/previews/ButtonPreview.js
import React from "react";

const ButtonPreview = ({ config }) => {
  const {
    label,
    backgroundColor,
    textColor,
    padding,
    borderRadius,
    fontSize,
    fontWeight,
    borderStyle,
    hoverBackgroundColor,
    hoverTextColor,
  } = config;

  const buttonStyle = {
    backgroundColor: backgroundColor || "#007bff",
    color: textColor || "#ffffff",
    padding: padding || "10px 20px",
    borderRadius: borderRadius || "5px",
    border: borderStyle || "none",
    fontSize: fontSize || "16px",
    fontWeight: fontWeight || "normal",
    cursor: "pointer",
    transition: "all 0.3s ease",
  };

  const hoverStyle = `
    button:hover {
      background-color: ${hoverBackgroundColor || "#0056b3"};
      color: ${hoverTextColor || "#ffffff"};
    }
  `;

  return (
    <div>
      <style>{hoverStyle}</style>
      <button style={buttonStyle}>
        {label || "Click Me"}
      </button>
    </div>
  );
};

export default ButtonPreview;
