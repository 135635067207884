import React, { useState } from "react";
import "../../configcomponents/Form.css";

const ColorPickerPreview = ({ config }) => {
  const { label, width, height, ActiveWhen, InactiveWhen } = config;
  const [selectedColor, setSelectedColor] = useState("#000000");

  const handleColorChange = (event) => {
    setSelectedColor(event.target.value);
  };
  return (
    <div>
      <div style={{ marginBottom: "20px" }}>
      {label && <label>{label}</label>}
       <input
        type="color"
        value={selectedColor}
        onChange={handleColorChange}
        className="color-picker-input"
         style={{
          width: `${width}px`, 
          height: `${height}px`, 
        }}
      />
       
        {/**<p className="color-preview">Selected Color: {selectedColor}</p>*/}
      </div>
    </div>
  );
};

export default ColorPickerPreview;
