import React, {useState} from "react";
import CarouselConfiguration from "../../../../user_components/carousel/CarouselConfiguration";
import BannerConfiguration from "../../../../user_components/banner/BannerConfiguration";
import FooterConfiguration from "../../../../user_components/footer/FooterConfiguration";
import NavbarConfigurator from "../../../../user_components/navbar/Nav_config";
import SideBarConfigForm from "../../../../user_components/sidebar/SideBarConfigForm";
import FormConfiguration from "../../../../user_components/form/FormConfiguration";
import SidebarBodyConfigForm from "../../../../user_components/sidebar/SidebarBodyConfigForm";
import ComponentConfigPage from "../../../../configcomponents/ComponentConfigPage";

const DUMMY_COMPONENT_TABLE_DATA = [
  { componentID: 12, componentName: "Navbar" },
  { componentID: 13, componentName: "Carousel" },
  { componentID: 14, componentName: "Banner" },
  { componentID: 18, componentName: "Sidebar" },
  { componentID: 18, componentName: "Sidebar - Body" },
  { componentID: 19, componentName: "Footer" },
  { componentID: 20, componentName: "Form" },
];

const COMPONENTS_MAP = {
  Navbar:NavbarConfigurator ,
  Carousel: CarouselConfiguration,
  Banner: BannerConfiguration,
  "Sidebar": SideBarConfigForm,
  "Sidebar - Body": SidebarBodyConfigForm,
  Footer: FooterConfiguration,
  Form: FormConfiguration,
};

const Components = () => {
  const [selectedComponent, setSelectedComponent] = useState("");
   const [activeTab, setActiveTab] = useState("add");
 const tabs = [
    { id: "add", label: "Add" },
    { id: "edit", label: "Edit" },
    { id: "view", label: "View" },
  ];
  const handleChange = (event) => {
    setSelectedComponent(event.target.value);
  };

  const SelectedComponent = COMPONENTS_MAP[selectedComponent];

  return (
    // <div className="container mx-auto p-4">
    //   {/* Tabs */}
    //   <div className="flex border-b mb-4">
    //     {["add", "edit", "view"].map((tab) => (
    //       <button
    //         key={tab}
    //         className={`px-4 py-2 text-sm font-medium ${
    //           activeTab === tab
    //             ? "border-b-2 border-blue-500 text-blue-500"
    //             : "text-gray-500 hover:text-blue-500"
    //         }`}
    //         onClick={() => setActiveTab(tab)}
    //       >
    //         {tab.charAt(0).toUpperCase() + tab.slice(1)}
    //       </button>
    //     ))}
    //   </div>

    //   {/* Tab Content */}
    //   <div className="p-4 border rounded-lg bg-white shadow-sm">
    //     {activeTab === "add" && (
    //       <form className="space-y-4">
    //         <div>
    //           <label htmlFor="add-name" className="block text-sm font-medium text-gray-700">
    //             Name
    //           </label>
    //           <input
    //             type="text"
    //             id="add-name"
    //             className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
    //           />
    //         </div>
    //         <button
    //           type="submit"
    //           className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
    //         >
    //           Add
    //         </button>
    //       </form>
    //     )}
    //     {activeTab === "edit" && (
    //       <form className="space-y-4">
    //         <div>
    //           <label htmlFor="edit-id" className="block text-sm font-medium text-gray-700">
    //             ID
    //           </label>
    //           <input
    //             type="text"
    //             id="edit-id"
    //             className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
    //           />
    //         </div>
    //         <div>
    //           <label htmlFor="edit-name" className="block text-sm font-medium text-gray-700">
    //             New Name
    //           </label>
    //           <input
    //             type="text"
    //             id="edit-name"
    //             className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
    //           />
    //         </div>
    //         <button
    //           type="submit"
    //           className="px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600"
    //         >
    //           Edit
    //         </button>
    //       </form>
    //     )}
    //     {activeTab === "view" && (
    //       <form className="space-y-4">
    //         <div>
    //           <label htmlFor="view-id" className="block text-sm font-medium text-gray-700">
    //             Enter ID to View
    //           </label>
    //           <input
    //             type="text"
    //             id="view-id"
    //             className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
    //           />
    //         </div>
    //         <button
    //           type="button"
    //           className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
    //         >
    //           View
    //         </button>
    //       </form>
    //     )}
    //   </div>
    // </div>
     <div className="container p-6">
      {/* Tabs */}
      <div className="flex justify-center space-x-6 mb-6">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            className={`px-6 py-2 rounded-full text-lg font-semibold shadow-lg transition-all duration-300
              ${
                activeTab === tab.id
                  ? "bg-blue-600 text-white hover:bg-blue-500"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
              }`}
          >
            {tab.label}
          </button>
        ))}
      </div>

      {/* Tab Content */}
      <div className="p-6 border rounded-lg shadow-md bg-gray-50">
        {activeTab === "add" && (
          <ComponentConfigPage/>
        )}
        {activeTab === "edit" && (
          <form>
            <h2 className="text-xl font-bold mb-4">Edit Form</h2>
            <input
              type="text"
              placeholder="Edit data"
              className="w-full p-2 border rounded-md mb-4"
            />
            <button
              type="submit"
              className="px-4 py-2 bg-yellow-600 text-white rounded hover:bg-yellow-500"
            >
              Update
            </button>
          </form>
        )}
        {activeTab === "view" && (
          <div>
            <h2 className="text-xl font-bold mb-4">View Details</h2>
            <p>Here are the details to view...</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Components;
