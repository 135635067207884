import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  getTokenFromLocalStorage,
  setTokenToLocalStorage,
} from "../../utils/utils/LocalStorageUtils";
import Notification from "../../services/Notification";


const useProfileValidation = (values) => {
  const [errors, setErrors] = useState({});
   const [emailNotified, setEmailNotified] = useState(false);
  const location = useLocation();


  const validate = () => {
    let tempErrors = {}; // Initialize tempErrors


    if (location.pathname === "/auth/userDataEntry") {  // Check if the user is on the "/auth/userDataEntry" route
      // Phone number validation for "/auth/userDataEntry"
      // The phone number is not required, but if provided, it must be exactly 10 digits
      if (values.phoneNumber && values.phoneNumber.length !== 10) {
        tempErrors.phoneNumber = 'Phone number must be exactly 10 digits.';
        Notification.error("Error","Phone number must be exactly 10 digits.");
      }
    }


    if (location.pathname === '/userprofile') {

       const curToken = getTokenFromLocalStorage("token");

          if (curToken) {
            const storedEmail = getTokenFromLocalStorage("email");


                //  if (!values.email || values.email !== storedEmail) {
                //         tempErrors.email = 'Email must match the one in your profile.';
                //         Notification.error("Error","Email doesn't match with registered email ");
                //       }

                        if (!values.phoneNumber) {
                            tempErrors.phoneNumber = 'Phone number is required.';
                            Notification.error("Error","Phone number is required.");
                          } else if (!/^\d{10}$/.test(values.phoneNumber)) {
                             tempErrors.phoneNumber = 'Phone number must be exactly 10 digits.';
                             Notification.error("Error","Phone number must be exactly 10 digits. ");
                               console.log("Phone number must be exactly 10 digits");
                          }

                      if (!values.firstName) {
                        tempErrors.firstName = 'First Name is required.';
                        Notification.error("Error","First Name is required.");

                      }
                      if (!values.dateOfBirth) {
                        tempErrors.dateOfBirth = 'Date of Birth is required.';
                        Notification.error("Error","Date of Birth is required.");
                      }
            }
            else{ Notification.error("Error","Try to login again");}

    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0; // Return true if no errors
  };

  return {errors,validate,};
};

export default useProfileValidation;
