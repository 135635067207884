import React, { useRef } from 'react';
import profilePlaceholder from "./profile.png";
import "./p.css";
import axios from "axios";
import {
   getTokenFromLocalStorage,
   setTokenToLocalStorage,
} from "../../utils/utils/LocalStorageUtils";
import profileStore from "../../store/profileStore";
import Notification from "../../services/Notification";
import { useNavigate, useLocation } from "react-router-dom";
import {
  showSuccessToast,
  showWarningToast,
  showErrorToast,
} from "../Toast/ToastNotification";
import { BASE_URL } from '../../utils/utils/constants';

 const UserImageUpload = () =>{
   const location = useLocation();
   const setUserImage = profileStore((state) => state.setUserImage);
   const userImage = profileStore((state) => state.userImage);
   const signupEmail = profileStore((state) => state.signupEmail);
   const isUserDataEntryRoute = location.pathname === "/auth/userDataEntry";
   const fileInputRef = useRef(null);

     const handleImageRemove = () => {
       setUserImage(null);
       if (fileInputRef.current) {
         fileInputRef.current.value = '';
       }
     };

   const handleImageUpload = () => {
       const input = document.createElement("input");
       input.type = "file";
       input.accept = "image/*";
       input.onchange = async (e) => {
        e.preventDefault();
         const newImage = e.target.files[0];
         if(newImage){
              setUserImage(newImage);
               const emailImage = isUserDataEntryRoute ? signupEmail : getTokenFromLocalStorage("email");
               const imageData = new FormData();
               imageData.append("file", newImage);


              try{

                  const resImage = await axios.post(`${BASE_URL}/upload-picture?email=${emailImage}`,
                              imageData,
                            {
                              headers: { "Content-Type": "multipart/form-data" },
                            }
                  );
                  if(resImage.data){
                       console.log("Image Uploaded successfully:", resImage.data);
                        // Notification.success("Success", "Image Uploaded successfully");
                        showSuccessToast("Image Uploaded successfully");
                  }
                   else {
                       console.error("Failed to upload image:", resImage.data);
                          Notification.error("Error", "Failed to upload image");
                       }

              }catch(error){
                   console.log("error log : 1",error);
                   console.log("error log : 2",error.response.data );
                   console.log("error log : 3",error.response);
                  if(error.response){
                       if(error.response.status === 404){
                         console.log("error log : 4", error.message);
                         showWarningToast(
                           "There was a problem with your request."
                         );

                       } else if (error.response.status === 500) {
                         showErrorToast("Failed to upload images.");
                       } else {
                         console.log("error log : 5", error.response.data);
                       }

                   }
                  else {
                      console.log("unexpected error occurred",error);

                      }
               }
         }
       }; 
       input.click();
     };

//const url = profilePictureUrl;
//const filename = url.split("/").pop(); // Extracts the last segment
//console.log("image url 2",filename); // Output: 93086b06-ad5c-4b4b-9e55-79dc7fe849e1_Passport_Photograph (5).jpg

    return(
          <div className="relative flex justify-center mb-28 rounded-lg w-full mx-auto">
               <div className="profile-image" onClick={handleImageUpload}>
                 {userImage && (
                      <button
                        type="button"
                        className="border-[3.5px] border-gray-300 absolute top-[4px] right-[310px] bg-red-500 text-white rounded-full w-8 h-8 flex items-center justify-center cursor-pointer transition-colors duration-300 hover:bg-red-700"
                        onClick={handleImageRemove}
                      >
                       X
                      </button>
                 )}
                 <img
                     src={ userImage ? URL.createObjectURL(userImage) :  profilePlaceholder  }
                     alt="Profile"
                 />
              </div>
          </div>

    );

};
export default UserImageUpload;