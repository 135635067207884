// src/components/previews/InputPreview.js
import React from "react";

const TextInputPreview = ({ config }) => {
  const {
    placeholder,
    value,
    width,
    fontSize,
    fontColor,
    backgroundColor,
    borderColor,
    padding,
    borderRadius,
    textAlign,
    margin,
    fontFamily,
  } = config;

  return (
    <input
      type="text"
      placeholder={placeholder || ""}
      value={value}
      readOnly
      style={{
        width: `${width}px`,
        fontSize: `${fontSize}px`,
        color: fontColor,
        backgroundColor,
        borderColor,
        borderWidth: "1px",
        borderStyle: "solid",
        padding: `${padding}px`,
        borderRadius: `${borderRadius}px`,
        textAlign,
        margin: `${margin}px`,
        fontFamily,
      }}
    />
  );
};

export default TextInputPreview;
