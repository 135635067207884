import React from "react";
import {
    FaFont,
    FaPalette,
    FaLock,
    FaDrawPolygon,
    FaExpandArrowsAlt,
    FaBorderAll,
} from "react-icons/fa";

import PasswordInputPreview from "../previews/PasswordInputPreview";
import AccordionItem from "./Accordion/Accordion";
import { MdLabel } from "react-icons/md";

export const getDefaultPasswordInputConfig = () => ({
    placeholder: "",
    value: "",
    width: 200,
    fontSize: 16,
    color: "#000000",
    backgroundColor: "#ffffff",
    borderColor: "#cccccc",
    borderRadius: 4,
    padding: 8,
});

const PasswordInputForm = ({ config, onConfigChange }) => {
    const handleChange = (e) => {
        const { name, value, type } = e.target;
        onConfigChange({
            ...config,
            [name]: type === "number" ? parseInt(value) || 0 : value,
        });
    };

    return (
        <div className="p-6 rounded-lg shadow-md font-sans">
            <h3 className="font-semibold text-gray-800 mb-4">
                Password Input Settings
            </h3>

            {/* Accordion 1: General Settings */}
            <AccordionItem Title={<><MdLabel /> General Settings</>} panelName="Basic">
            <div className="mb-4 border rounded-lg">
                    <div className="p-4 space-y-4">
                        <div className="flex items-center space-x-2">
                            <FaLock className="text-gray-500" />
                            <label className="text-sm font-medium text-gray-700">
                                Placeholder:
                            </label>
                            <input
                                type="text"
                                name="placeholder"
                                value={config.placeholder}
                                onChange={handleChange}
                                className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                        <div className="flex items-center space-x-2">
                            <FaFont className="text-gray-500" />
                            <label className="text-sm font-medium text-gray-700">
                                Font Size:
                            </label>
                            <input
                                type="number"
                                name="fontSize"
                                value={config.fontSize}
                                onChange={handleChange}
                                className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                        <div className="flex items-center space-x-2">
                            <FaExpandArrowsAlt className="text-gray-500" />
                            <label className="text-sm font-medium text-gray-700">
                                Width:
                            </label>
                            <input
                                type="number"
                                name="width"
                                value={config.width}
                                onChange={handleChange}
                                className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                    </div>
            </div>
            </AccordionItem>

            {/* Accordion 2: Appearance Settings */}
            <AccordionItem Title={<><MdLabel /> Appearance Settings</>} panelName="Basic">
            <div className="mb-4 border rounded-lg">
                    <div className="p-4 space-y-4">
                        <div className="flex items-center space-x-2">
                            <FaPalette className="text-gray-500" />
                            <label className="text-sm font-medium text-gray-700">
                                Text Color:
                            </label>
                            <input
                                type="color"
                                name="color"
                                value={config.color}
                                onChange={handleChange}
                                className="w-16 h-10 border border-gray-300 rounded-md"
                            />
                        </div>
                        <div className="flex items-center space-x-2">
                            <FaPalette className="text-gray-500" />
                            <label className="text-sm font-medium text-gray-700">
                                Background Color:
                            </label>
                            <input
                                type="color"
                                name="backgroundColor"
                                value={config.backgroundColor}
                                onChange={handleChange}
                                className="w-16 h-10 border border-gray-300 rounded-md"
                            />
                        </div>
                        <div className="flex items-center space-x-2">
                            <FaBorderAll className="text-gray-500" />
                            <label className="text-sm font-medium text-gray-700">
                                Border Color:
                            </label>
                            <input
                                type="color"
                                name="borderColor"
                                value={config.borderColor}
                                onChange={handleChange}
                                className="w-16 h-10 border border-gray-300 rounded-md"
                            />
                        </div>
                    </div>
            </div>
            </AccordionItem>

            {/* Accordion 3: Border & Spacing */}
            <AccordionItem Title={<><MdLabel /> Border & Spacing Settings</>} panelName="Basic">
            <div className="mb-4 border rounded-lg">
                    <div className="p-4 space-y-4">
                        <div className="flex items-center space-x-2">
                            <FaDrawPolygon className="text-gray-500" />
                            <label className="text-sm font-medium text-gray-700">
                                Border Radius:
                            </label>
                            <input
                                type="number"
                                name="borderRadius"
                                value={config.borderRadius}
                                onChange={handleChange}
                                className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                        <div className="flex items-center space-x-2">
                            <FaExpandArrowsAlt className="text-gray-500" />
                            <label className="text-sm font-medium text-gray-700">
                                Padding:
                            </label>
                            <input
                                type="number"
                                name="padding"
                                value={config.padding}
                                onChange={handleChange}
                                className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                    </div>
            </div>
            </AccordionItem>
        </div>
    );
};

export default PasswordInputForm;
export { PasswordInputPreview };