import React, { useState } from "react";
import { Select, Input, Button } from "antd";
// import useFormDate from '../../store/useFormDate';

const MultiselectPreview = ({ config }) => {
  const {
    label,
    backgroundColor,
    borderRadius,
    placeholder,
    tooltip,
    width,
    disabled,
    required,
    activeWhen,
    inactiveWhen,
    options,
  } = config;

  const handleChange = (value) => {
    console.log("check val:",value);
    console.log(`selected ${value}`);
  };

  return (
    <div>
      <div style={{ marginBottom: "20px" }}>
        {label && <label>{label}</label>}
        <Select
        mode="multiple"
        allowClear
          placeholder={placeholder}
          onChange={handleChange}
          options={options}
          size="medium"
          style={{
            width: `${width}%`,
          }}
        />
      </div>
    </div>
  );
};

export default MultiselectPreview;
