import React from 'react'
import { Accordion } from './Accordion/Accordion';
import { FaCaretDown, FaCog, FaPalette, } from "react-icons/fa";
import { MdLabel } from "react-icons/md";
import AccordionItem from './Accordion/Accordion';


export const getDefaultDateTimePickerFormConfig = () => ({
    minDateTime: getCurrentDateTime(), 
    maxDateTime: '', 
    placeholder: 'Select Date and Time', 
    defaultDateTime: getCurrentDateTime(),
    width: "30",
    label: "Pick Your Date&Time",
    disabled: false,
    required: false,
    tooltip: "",
    ActiveWhen: true,
    InactiveWhen: true,
    backgroundColor: "#ffffff",
    borderRadius: "4",
});

const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const date = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${date}T${hours}:${minutes}`;
  };

const DateTimePickerForm = ({ config, onConfigChange }) => {
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        onConfigChange((prevConfig) => ({
            ...prevConfig,
            [name]: type === 'checkbox' ? checked : value
        }));

    };
    return (
        <>
            <div>
                <Accordion>
                    <AccordionItem Title={<><MdLabel /> Basic Settings</>} panelName="Basic">
                        <label>
                            Label:
                            <input
                                type="text"
                                name="label"
                                value={config.label}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            Background Color:
                            <input
                                type="color"
                                name="backgroundColor"
                                value={config.backgroundColor}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            Border Radius:
                            <input
                                type="text"
                                name="borderRadius"
                                value={config.borderRadius}
                                onChange={handleChange}
                            />
                        </label>
                        <label>Minimum DateTime
                        <input
                            type="datetime-local"
                            name="minDateTime"
                            value={config.minDateTime}
                            onChange={handleChange}
                        />
                        </label>
                    
                        <label>Maximum DateTime
                        <input
                            type="datetime-local"
                            name="maxDateTime"
                            value={config.maxDateTime}
                            onChange={handleChange}
                        />
                        </label>
                   
                        <label>Default DateTime
                        <input
                            type="datetime-local"
                            name="defaultDateTime"
                            value={config.defaultDateTime}
                            onChange={handleChange}
                        />
                        </label>
                   
                        <label>Placeholder
                        <input
                            type="text"
                            name="placeholder"
                            placeholder="Placeholder text"
                            value={config.placeholder}
                            onChange={handleChange}
                        />
                        </label>
                    </AccordionItem>




                    <AccordionItem Title={<><FaCog /> Advanced Settings</>} panelName="Advance">


                        <label>
                            Hint Box:
                            <input
                                type="text"
                                name="tooltip"
                                value={config.tooltip}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            Width of the field:
                            <input
                                type="text"
                                name="width"
                                value={config.width}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            Disabled the field:
                            <input
                                type="checkbox"
                                name="disabled"
                                checked={config.disabled}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            Make the field mandatory :
                            <input
                                type="checkbox"
                                name="required"
                                checked={config.required}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            Active When:
                            <input
                                type="checkbox"
                                name="activeWhen"
                                checked={config.ActiveWhen}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            Inactive When:
                            <input
                                type="checkbox"
                                name="inactiveWhen"
                                checked={config.InactiveWhen}
                                onChange={handleChange}
                            />
                        </label>
                    </AccordionItem>
                </Accordion>


            </div>

        </>

    );
};

export default DateTimePickerForm;