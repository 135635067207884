const CheckboxPreview = ({ config }) => (
  <div>
    {config.checkboxes.map((checkbox) => (
      <div
        key={checkbox.id}
        style={{
          backgroundColor: checkbox.backgroundColor,
          border: `1px solid ${checkbox.borderColor}`,
          borderRadius: checkbox.borderRadius,
          padding: checkbox.padding,
          margin: checkbox.margin,
          color: checkbox.textColor,
        }}
      >
        <label>
          <input
            type="checkbox"
            checked={checkbox.checked}
            disabled={checkbox.disabled}
          />
          <span style={{ color: checkbox.textColor }}>{checkbox.label}</span>
        </label>
      </div>
    ))}
  </div>
);

export default CheckboxPreview;
