import React from "react";

const SwitchPreview = ({ config }) => {
  const sizeStyles = {
    small: { width: "30px", height: "15px", knob: "12px" },
    medium: { width: "40px", height: "20px", knob: "16px" },
    large: { width: "50px", height: "25px", knob: "20px" },
  };

  return (
    <div>
      {config.switches.map((switchConfig, index) => {
        const {
          label,
          checked,
          disabled,
          size,
          activeColor,
          inactiveColor,
          tooltip,
          labelPosition,
        } = switchConfig;

        const currentSize = sizeStyles[size];

        return (
          <div
            key={index}
            title={tooltip}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              cursor: disabled ? "not-allowed" : "pointer",
            }}
          >
            {labelPosition === "before" && (
              <span style={{ marginRight: "10px", color: disabled ? "#aaa" : "#000" }}>{label}</span>
            )}

            <div
              style={{
                width: currentSize.width,
                height: currentSize.height,
                backgroundColor: disabled
                  ? "#e0e0e0"
                  : checked
                  ? activeColor
                  : inactiveColor,
                borderRadius: "999px",
                position: "relative",
                transition: "background-color 0.3s",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: checked ? `calc(100% - ${currentSize.knob})` : "0",
                  transform: "translateY(-50%)",
                  width: currentSize.knob,
                  height: currentSize.knob,
                  backgroundColor: "#fff",
                  borderRadius: "50%",
                  transition: "left 0.3s",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                }}
              ></div>
            </div>

            {labelPosition === "after" && (
              <span style={{ marginLeft: "10px", color: disabled ? "#aaa" : "#000" }}>{label}</span>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default SwitchPreview;
