import React, { useState } from "react";

import { MdLabel } from "react-icons/md";
import AccordionItem, { Accordion } from "./Accordion/Accordion";

export const getDefaultSliderConfig = () => ({
    name: "MySlider",
    min: 0,
    height:7,
    width:30,
    max: 5,
    step: 1,
    color: "#0000ff",
    
});



const SliderForm = ({ config, onConfigChange }) => {
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        onConfigChange({
            ...config,
            [name]: type === "checkbox" ? checked : value,
        });
    };
    return (
        <div>
            <Accordion>
                <AccordionItem
                    Title={
                        <>
                            <MdLabel /> Basic Settings
                        </>
                    }
                    panelName="Basic"
                >
                <label>
                    Name:
                    <input
                        type="text"
                        name="name"
                        value={config.name}
                        onChange={handleChange}
                    />
                </label>
                
                <label>
                    Height:
                    <input
                        type="number"
                        name="height"
                        value={config.height}
                        onChange={handleChange}
                    />
                </label>
                <label>
                    Width:
                    <input
                        type="number"
                        name="width"
                        value={config.width}
                        onChange={handleChange}
                    />
                </label>
                <label>
                    Minimum Value:
                    <input
                        type="number"
                        name="min"
                        value={config.min}
                        onChange={handleChange}
                    />
                </label>
                <label>
                    Maximum Value:
                    <input
                        type="number"
                        name="max"
                        value={config.max}
                        onChange={handleChange}
                    />
                </label>
                <label>
                    Step:
                    <input
                        type="number"
                        name="step"
                        value={config.step}
                        onChange={handleChange}
                    />
                </label>
               
                <label>
                    Color:
                    <input
                        type="color"
                        name="color"
                        value={config.color}
                        onChange={handleChange}
                    />
                </label>
               
                </AccordionItem>
              </Accordion>
        </div>
    );
};

export default SliderForm;