import React from "react";
import TextInputPreview from "../previews/TextInputPreview";
import {
  FaFont,
  FaPaintBrush,
  FaBorderStyle,
  FaAlignLeft,
  FaRulerVertical,
  FaPalette,
  FaArrowsAltV,
} from "react-icons/fa";
import AccordionItem from "./Accordion/Accordion";
import { MdLabel } from "react-icons/md";

export const getDefaultInputConfig = () => ({
  placeholder: "",
  value: "",
  width: 200,
  fontSize: 16,
  fontColor: "#000000",
  backgroundColor: "#ffffff",
  borderColor: "#ccc",
  padding: 8,
  borderRadius: 4,
  textAlign: "left",
  margin: 10,
  fontFamily: "Arial, sans-serif",
});

const TextInputForm = ({ config, onConfigChange }) => {
  const handleChange = (e) => {
    const { name, value, type } = e.target;
    const updatedValue = type === "number" ? parseFloat(value) || 0 : value;
    onConfigChange({ ...config, [name]: updatedValue });
  };

  return (
    <div>
      <h3 style={{ textAlign: "center", marginBottom: "20px" }}>
        Text Input Component
      </h3>

      {/* Accordion 1: General Settings */}
      <AccordionItem Title={<><MdLabel /> General Settings</>} panelName="Basic">
      <div>
          <div>
            <label>
              <FaFont /> Placeholder:
              <input
                type="text"
                name="placeholder"
                value={config.placeholder}
                onChange={handleChange}
                placeholder="Enter text..."
              />
            </label>
            <label>
              <FaRulerVertical /> Width :
              <input
                type="number"
                name="width"
                value={config.width}
                onChange={handleChange}
                min="0"
              />
            </label>
          </div>
      </div>
      </AccordionItem>

      {/* Accordion 2: Styling */}
      <AccordionItem Title={<><MdLabel /> Styling Settings</>} panelName="Basic">
      <div>
          <div>
            <label>
              <FaFont /> Font Size :
              <input
                type="number"
                name="fontSize"
                value={config.fontSize}
                onChange={handleChange}
                min="1"
              />
            </label>
            <label>
              <FaPalette /> Font Color :
              <input
                type="color"
                name="fontColor"
                value={config.fontColor}
                onChange={handleChange}
              />
            </label>
            <label>
              <FaPaintBrush /> Background Color :
              <input
                type="color"
                name="backgroundColor"
                value={config.backgroundColor}
                onChange={handleChange}
              />
            </label>
            <label>
              <FaBorderStyle /> Border Color :
              <input
                type="color"
                name="borderColor"
                value={config.borderColor}
                onChange={handleChange}
              />
            </label>
            <label>
              <FaArrowsAltV /> Padding :
              <input
                type="number"
                name="padding"
                value={config.padding}
                onChange={handleChange}
                min="0"
              />
            </label>
            <label>
              <FaArrowsAltV /> Border Radius :
              <input
                type="number"
                name="borderRadius"
                value={config.borderRadius}
                onChange={handleChange}
                min="0"
              />
            </label>
          </div>
      </div>
      </AccordionItem>

      {/* Accordion 3: Layout */}
      <AccordionItem Title={<><MdLabel /> Layout Settings</>} panelName="Basic" >
      <div>
          <div>
            <label>
              <FaAlignLeft /> Text Align:
              <select
                name="textAlign"
                value={config.textAlign}
                onChange={handleChange}
              >
                <option value="left">Left</option>
                <option value="center">Center</option>
                <option value="right">Right</option>
              </select>
            </label>
            <label>
              <FaArrowsAltV /> Margin :
              <input
                type="number"
                name="margin"
                value={config.margin}
                onChange={handleChange}
                min="0"
              />
            </label>
            <label>
              <FaFont /> Font Family:
              <select
                name="fontFamily"
                value={config.fontFamily}
                onChange={handleChange}
              >
                <option value="Arial, sans-serif">Arial</option>
                <option value="'Courier New', monospace">Courier New</option>
                <option value="'Times New Roman', serif">Times New Roman</option>
                <option value="'Verdana', sans-serif">Verdana</option>
              </select>
            </label>
          </div>
      </div>
      </AccordionItem>
    </div>
  );
};

export default TextInputForm;
export { TextInputPreview };
