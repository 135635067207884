// src/components/previews/CardPreview.js
import React from "react";
import formComponents from "../forms/formComponents";

const CardPreview = ({ config }) => {
  return (
    <div
      style={{
        backgroundColor: config.backgroundColor,
        padding: config.padding,
        margin: config.margin,
        border: config.border,
        borderRadius: config.borderRadius,
        boxShadow: config.shadow,
      }}
    >
      {/* {config.nestedComponents.map((nestedComponent, index) => {
        const PreviewComponent = formComponents[nestedComponent.type]?.preview;
        return PreviewComponent ? (
          <PreviewComponent config={nestedComponent.config} key={index} />
        ) : null;
      })} */}
      {Array.isArray(config?.nestedComponents) &&
        config.nestedComponents.map((nestedComponent, index) => {
          const PreviewComponent = formComponents[nestedComponent.type]?.preview;
          return PreviewComponent ? (
            <PreviewComponent config={nestedComponent.config} key={index} />
          ) : null;
        })}
    </div>
  );
};

export default CardPreview;


