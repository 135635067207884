import React, { useState } from "react";

const SliderPreview = ({ config }) => {
    const { name, min, max, step, width, height, color, size } = config;

    const [value, setValue] = useState('0');
    console.log(`selected value`, value);


    const handleSliderChange = (e) => {
        setValue(e.target.value);
      
    };

    return (
        <div >
            {name && <label>{name}</label>}
            <div className="flex flex-col ">
                <div>
                    <input
                        type="range"
                        min={min}
                        max={max}
                        step={step}
                        value={value}

                        onChange={handleSliderChange}
                        className="rounded-lg appearance-none cursor-pointer"
                        style={{ width: `${width}%`, height: `${height}px`, accentColor: color }}

                    ></input>
                </div>


                <div className="  font-medium p-4 rounded-md justify-start" style={{ color }}>
                    Value: {value} 
                </div>
            </div>
        </div>
    );
};

export default SliderPreview;
