import React, { useState } from "react";
import {
    FaPlus,
    FaTrash,
    FaCheck,
    FaTimes,
    FaFont,
    FaPalette,
    FaTextHeight,
    FaDotCircle,
    FaAlignLeft,
    FaAlignRight,
    FaAlignCenter,
} from "react-icons/fa";
import RadioGroupPreview from "../previews/RadioGroupPreview";
import { MdLabel } from "react-icons/md";
import AccordionItem from "./Accordion/Accordion";

export const getDefaultRadioGroupConfig = () => ({
    options: ["Option 1", "Option 2", "Option 3"],
    selectedOption: "",
    disabled: false,
    labelPosition: "right",
    fontSize: 16,
    fontFamily: "Arial, sans-serif",
    color: "#000000",
});

const RadioGroupForm = ({ config, onConfigChange }) => {
    const [newOption, setNewOption] = useState("");
    const handleConfigChange = (e) => {
        const { name, value } = e.target;
        onConfigChange({ ...config, [name]: value });
    };

    const handleAddOption = () => {
        if (newOption.trim()) {
            onConfigChange({
                ...config,
                options: [...config.options, newOption],
            });
            setNewOption("");
        }
    };

    const handleOptionChange = (index, value) => {
        const updatedOptions = [...config.options];
        updatedOptions[index] = value;
        onConfigChange({ ...config, options: updatedOptions });
    };

    const handleDeleteOption = (index) => {
        const updatedOptions = config.options.filter((_, i) => i !== index);
        onConfigChange({ ...config, options: updatedOptions });
    };

    return (
        <div className="p-6 border rounded-lg shadow-lg bg-white">
            <h3 className="text-xl font-semibold mb-6 flex items-center text-gray-700">
                <FaDotCircle className="mr-2 text-blue-500" />
                Radio Group Settings
            </h3>

            {/* General Settings Accordion */}
            <AccordionItem Title={<><MdLabel /> General Settings</>} panelName="Basic">
            <div className="mb-4">
                    <div className="p-4 border rounded-lg mt-2 bg-gray-50">
                        <label className="block mb-6">
                            <strong className="flex items-center text-gray-700">
                                <FaCheck className="mr-2 text-green-500" />
                                Selected Option:
                            </strong>
                            <input
                                type="text"
                                name="selectedOption"
                                value={config.selectedOption}
                                onChange={handleConfigChange}
                                className="w-full mt-2 p-2 border rounded-lg focus:ring focus:ring-blue-200"
                            />
                        </label>

                        <label className="block mb-6">
                            <strong className="flex items-center text-gray-700">
                                <FaTimes className="mr-2 text-red-500" />
                                Disabled:
                            </strong>
                            <input
                                type="checkbox"
                                name="disabled"
                                checked={config.disabled}
                                onChange={(e) =>
                                    onConfigChange({ ...config, disabled: e.target.checked })
                                }
                                className="ml-2 accent-blue-500"
                            />
                        </label>

                        <label className="block mb-6">
                            <strong className="flex items-center text-gray-700">
                                <FaAlignRight className="mr-2 text-blue-500" />
                                Label Position:
                            </strong>
                            <select
                                name="labelPosition"
                                value={config.labelPosition}
                                onChange={handleConfigChange}
                                className="w-full mt-2 p-2 border rounded-lg focus:ring focus:ring-blue-200"
                            >
                                <option value="left">Left</option>
                                <option value="right">Right</option>
                                <option value="top">Top</option>
                            </select>
                        </label>
                    </div>
            </div>
            </AccordionItem>

            {/* Appearance Settings Accordion */}
            <AccordionItem Title={<><MdLabel />Appearance Settings</>} panelName="Basic">
            <div className="mb-4">
                    <div className="p-4 border rounded-lg mt-2 bg-gray-50">
                        <label className="block mb-6">
                            <strong className="flex items-center text-gray-700">
                                <FaTextHeight className="mr-2 text-green-500" />
                                Font Size:
                            </strong>
                            <input
                                type="number"
                                name="fontSize"
                                value={config.fontSize}
                                onChange={handleConfigChange}
                                className="w-24 mt-2 p-2 border rounded-lg focus:ring focus:ring-blue-200"
                            />
                        </label>

                        <label className="block mb-6">
                            <strong className="flex items-center text-gray-700">
                                <FaFont className="mr-2 text-purple-500" />
                                Font Family:
                            </strong>
                            <select
                                name="fontFamily"
                                value={config.fontFamily}
                                onChange={handleConfigChange}
                                className="w-full mt-2 p-2 border rounded-lg focus:ring focus:ring-blue-200"
                            >
                                <option value="Arial, sans-serif">Arial</option>
                                <option value="Georgia, serif">Georgia</option>
                                <option value="Courier New, monospace">Courier New</option>
                                <option value="Times New Roman, serif">Times New Roman</option>
                                <option value="Verdana, sans-serif">Verdana</option>
                            </select>
                        </label>

                        <label className="block mb-6">
                            <strong className="flex items-center text-gray-700">
                                <FaPalette className="mr-2 text-yellow-500" />
                                Color:
                            </strong>
                            <input
                                type="color"
                                name="color"
                                value={config.color}
                                onChange={handleConfigChange}
                                className="ml-2"
                            />
                        </label>
                    </div>
                {/* )} */}
            </div>
            </AccordionItem>

            {/* Options Settings Accordion */}
            <AccordionItem Title={<><MdLabel />Options Settings</>} panelName="Basic">
            <div className="mb-4">
                    <div className="p-4 border rounded-lg mt-2 bg-gray-50">
                        <h4 className="text-lg font-semibold mb-4 flex items-center text-gray-700">
                            <FaPlus className="mr-2 text-green-500" />
                            Options
                        </h4>
                        {config.options.map((option, index) => (
                            <div key={index} className="mb-4 flex items-center">
                                <input
                                    type="text"
                                    value={option}
                                    onChange={(e) => handleOptionChange(index, e.target.value)}
                                    className="flex-1 p-2 border rounded-lg focus:ring focus:ring-blue-200 mr-2"
                                />
                                <button
                                    onClick={() => handleDeleteOption(index)}
                                    className="text-red-500 hover:text-red-600"
                                    title="Delete Option"
                                >
                                    <FaTrash />
                                </button>
                            </div>
                        ))}

                        <div className="mt-6 flex items-center">
                            <input
                                type="text"
                                value={newOption}
                                placeholder="Add new option"
                                onChange={(e) => setNewOption(e.target.value)}
                                className="flex-1 p-2 border rounded-lg focus:ring focus:ring-blue-200 mr-2"
                            />
                            <button
                                onClick={handleAddOption}
                                className="bg-green-500 text-white p-2 rounded-lg hover:bg-green-600"
                                title="Add Option"
                            >
                                <FaPlus />
                            </button>
                        </div>
                    </div>
            </div>
            </AccordionItem>
        </div>
    );
};

export default RadioGroupForm;
export { RadioGroupPreview };
