import { create } from "zustand";
import {
  createData,
  fetchAllData,
  fetchOneRecord,
} from "../environment/commonApi";

const useFormComponentStore = create((set, get) => ({
  components: [], // Initialize components as an array
  component: {},
  res_status: "", // Status messages for user feedback
  isLoading: false, // Loading state

  // Action to fetch all components
  fetchComponents: async () => {
    try {
      set({ isLoading: true, res_status: "Fetching components..." });
      const data = await fetchAllData("/component.json");

      // Extract component names (keys of the data object)
      const componentNames = data ? Object.keys(data) : [];

      set({
        components: componentNames, // Store only names in the state
        res_status: "Components fetched successfully!",
        isLoading: false,
      });
    } catch (error) {
      console.error("Error fetching components:", error);
      set({ res_status: "Failed to fetch components.", isLoading: false });
    }
  },

  // Action to fetch a single component
  fetchComponent: async (componentName) => {
    try {
      set({
        isLoading: true,
        res_status: `Fetching component: ${componentName}`,
      });

      // Use fetchOneRecord to get the data for the selected component
      const data = await fetchOneRecord("/component", componentName);
      console.log("data in store:", data);
      set({
        component: data, // Store the updated components array
        res_status: `Component ${componentName} fetched successfully!`,
        isLoading: false,
      });
    } catch (error) {
      console.error("Error fetching component:", error);
      set({ res_status: "Failed to fetch component.", isLoading: false });
    }
  },

  // Action to add a new component
  addComponent: async (componentName, componentData) => {
    try {
      set({
        isLoading: true,
        res_status: `Saving component: ${componentName}`,
      });

      // Save the new component
      // await createData(`/component/${componentName}.json`, componentData);
      await createData(`/component/${componentName}`, componentData);
      // Add the new component to the array
      const updatedComponents = [
        ...get().components,
        { name: componentName, ...componentData }, // Add the new component object
      ];

      set({
        components: updatedComponents,
        res_status: `Component ${componentName} saved successfully!`,
        isLoading: false,
      });
    } catch (error) {
      console.error("Error saving component:", error);
      set({ res_status: "Failed to save component.", isLoading: false });
    }
  },
}));

export default useFormComponentStore;
