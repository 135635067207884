import React, { useState, useEffect } from "react";
import { db } from "../firebase/firebaseConfig";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import formComponents from "../components/forms/formComponents";
import useFormComponentStore from "../store/useFormComponentStrore";
const ComponentViewerPage = () => {
  const [componentNames, setComponentNames] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState("");
  const [config, setConfig] = useState({});
  const [status, setStatus] = useState("");
  const { 
   component,
    components, 
    res_status, 
    isLoading, 
    fetchComponents,
    fetchComponent,
    addComponent 
  } = useFormComponentStore();
 useEffect(() => {
    const fetchAndSetComponents = async () => {
      try {
        await fetchComponents(); // This fetches and updates Zustand state with names
        setComponentNames(components);
      } catch (error) {
        console.error("Error fetching component names:", error);
      }
    };

    fetchAndSetComponents(); // Call the function on component mount
 }, [fetchComponents]);


  
 useEffect(() => {
    const fetchSingleComponent = async () => {
      try {
        if (selectedComponent) {
    fetchComponent(selectedComponent); // Fetch the selected component's data
  }
      } catch (error) {
        console.error("Error fetching component names:", error);
      }
    };

    fetchSingleComponent(); // Call the function on component mount
 }, [selectedComponent, fetchComponent,config]);
const handleSelectChange = (e) => {
  const docName = e.target.value; // Get the selected component name
  if (docName) {
    setSelectedComponent(docName); // Update the selected component state
  }
};
  const getPreviewComponent = (config) => {
    if (!config || !config.componentType) return null;
    const { componentType } = config;
    const PreviewComponent = formComponents[componentType]?.preview;
    return PreviewComponent ? (
      <PreviewComponent config={config} />
    ) : (
      <p>Preview not available for this component type.</p>
    );
  };

  return (
    <div className="flex w-full p-4 bg-gray-200">
      <div className="w-1/4 p-2">
        {/* view component select box */}
        <div className="p-6 bg-white max-w-md mx-auto shadow-lg rounded-md">
          <h1 className="text-2xl font-bold text-black mb-6">View Component</h1>
          <div className="mb-4">
            <label className="font-semibold text-lg block mb-1">
              Select Component
            </label>
            <select
              className="w-full border rounded-md px-2 py-1 text-gray-600"
              onChange={handleSelectChange}
              value={selectedComponent}
            >
              <option value="">Select a component</option>
              {components?.map((name) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="w-3/4 p-4 rounded-md border border-gray-500">
        {/* component view area */}
        {component && (
          <>
          {getPreviewComponent(component)}
          </>
        )}
      </div>
    </div>
  );
};

export default ComponentViewerPage;
