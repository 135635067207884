import React, { useState } from 'react';
import Notification from '../../services/Notification';
import useForgotPasswordValidation from './useForgotPasswordValidation';
import emailjs from "@emailjs/browser";
import axios from 'axios';
import './forgotpassword.css';
import { showErrorToast, showSuccessToast, showWarningToast } from '../Toast/ToastNotification';
import { BASE_URL } from '../../utils/utils/constants';
import apiURL from '../../config/apiConfig';
const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [passwordSent, setPasswordSent] = useState(false);
 const { errors, validateEmail } = useForgotPasswordValidation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) return;
    const resetToken = await CheckUser();
    if (resetToken) {
      SendResetLink(resetToken);
    } else {
      Notification.error("Error", "No account associated with this email.");
    }
  };
  

  const CheckUser = async () => {
      try {
        const response = await axios.post(`${BASE_URL}/forgot-password?email=${email}`);
        if (response.status === 200) {
          showSuccessToast("Email verified successfully");
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        // Notification.error("Error", "Failed to verify email.");
        if (error.response.status === 400) {
          showWarningToast("There was a problem with your request.");
        } else if (error.response.status === 500) {
          showErrorToast("Failed to verify email");
        } else {
          console.error("Error in verifying email:", error);
          showErrorToast("Failed to verify email.");
        }
        return null;
      }
    };
  

  const SendResetLink = async (resetToken) => {
    try {
      const resetLink = ` ${apiURL}auth/resetPassword?token=${resetToken}&email=${email}`;

      await emailjs.send(
        "service_8715ym8",
        "template_86kauod",
        { to_email: email, reset_link: resetLink },
        "GR1M3HK1I-dx7h-qU"
      );

      setPasswordSent(true);
      Notification.success("Success", "Reset link sent to your email!");
    } catch (error) {
      Notification.error("Error", "Failed to send reset email.");
    }
  };

  return (

  <div className='forgot-password-container'>
       <div className="form-wrapper">
         <h1 className="form-heading">Forgot your password?</h1>
         {!passwordSent ? (
           <form onSubmit={handleSubmit} className="form">
             <div className="form-item">
               <input
                 id="email"
                 name="email"
                 type="text"
                 value={email}
                 onChange={(e) => setEmail(e.target.value)}
                 placeholder=" "
                 className="form-input"
                 required
               />
               <label htmlFor="email" className="form-label">Your Email</label>
               {errors.email && (
                 <p className="form-error-message">{errors.email}</p>
               )}
             </div>

             <button type="submit" className="form-button">
               Send Reset Link
             </button>
             <div className='foot'>
             <h5>New here? <a href="/auth/signup" className='l1'>Sign Up.</a></h5>
                        <h5>Already have an account? <a href="/auth/signin" className='l1'>Sign In.</a></h5>
           </div>
           </form>
         ) : (
             <>
               <p className="form-success-message">
                 Check your email for a reset link.
               </p>
               <div className="w-full mt-8 flex justify-center items-center">
               <button className="bg-[#2c2c2c]  p-3 rounded-lg hover:bg-[#2c2c2c]-700 border border-gray-500 border-dashed text-gray-300 font-semibold py-2 px-4  shadow-md transition duration-300 ease-in-out  focus:outline-none focus:ring-2  focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-gray-800"
               onClick={handleSubmit}>Resend Email</button>
               </div>
            </>
         )}
       </div>
     </div>
  );
};

export default ForgotPassword;
