import React, { useState } from "react";
import QuestionForm from "./QuestionForm";
import { questionData } from "../../utils/utils/questionData";
import { optionsData } from "../../utils/utils/optionData";
import websiteImg from "../../assets/images/website.jpg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateApplicationName } from "../../store/userSlice";
import Api from "../../services/Api";
import Notification from "../../services/Notification";
import axios from "axios";
import { showErrorToast, showSuccessToast, showWarningToast } from "../Toast/ToastNotification";
import { BASE_URL } from "../../utils/utils/constants";
const Quiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [responses, setResponses] = useState({});
  const [loading, setLoading] = useState(true); 
  const dispatch = useDispatch();
  const handleNext = () => {
    if (currentQuestionIndex < questionData.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handleBack = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };
  
  const handleSaveAppData = async (applicationName, userId) => {
    try {
    const res = await axios.post(
        `${BASE_URL}/app/urlCreator`,
        {
          applicationName: applicationName,
          userId: `${userId}`,
        }
      );
      if (res.status === 200) {
          showSuccessToast("Questions saved successfully");
        }
      return res.data;
    } catch (error) {
      if (error.response.status === 400) {
          showWarningToast("There was a problem with your request.");
        } else if (error.response.status === 500) {
          showErrorToast("Failed to save Questions");
        } else {
          console.error("Error saving Questions:", error);
          showErrorToast("Failed to save Questions.");
        }
    }
    
  };

  const btnHandler = () => {
    const currentQuestion = questionData[currentQuestionIndex];
    const response = responses[currentQuestion.question_id];
    // Update the name with the application name here
    localStorage.setItem("application_name",Object.values(responses)[0]); // Ensure this line is executed
    // Validate that the current question is answered before proceeding
    if (!response || (Array.isArray(response) && response.length === 0)) {
      showErrorToast("Please answer the question before proceeding.");
      return;
    }

    // Check if it's the last question
    if (currentQuestionIndex === questionData.length - 1) {
      console.log("last qun");
      let applicationName = Object.values(responses)[0];
      if (!applicationName) {
        navigate("/config-steps/onboarding");
        return;
      }
      const userId = localStorage.getItem("userId");
      handleSaveAppData(applicationName, userId)
        .then((data) => {
           const arr = data.split("/");
          const appId = arr[arr.length - 1];
          const appName = arr[arr.length - 2];
          localStorage.setItem("appId", appId);
          showSuccessToast("Data Submitted Successfully!");
        })
        .catch((error) => {
          console.log("error", error);
          showErrorToast("An error occurred while saving application name!");
        });
      navigate("/config-steps/onboarding");
    } else {
      handleNext();
    }
  };

  const createOptionList = () => {
    let multipleOptions = [];
    let optionList = optionsData.filter(
      (option) =>
        option.question_id === questionData[currentQuestionIndex]?.question_id
    );
    optionList.forEach((option) => {
      let data = { value: option.option_value, label: option.option_value };
      multipleOptions.push(data);
    });
    return multipleOptions;
  };

  const handleResponseChange = (questionId, response) => {
    setResponses({ ...responses, [questionId]: response });
  };

  return (
    <>
  <div className="flex flex-col md:flex-row min-h-screen bg-gray-100">
      {/* Left Side - Form Section */}
      <div className="flex flex-col p-6 w-full md:w-6/12 items-center justify-center">
        <QuestionForm
          question={questionData[currentQuestionIndex]}
          multiSelectOptions={
            questionData[currentQuestionIndex].options_type === "multiselect"
              ? createOptionList()
              : []
          }
          options={optionsData.filter(
            (option) =>
              option.question_id ===
              questionData[currentQuestionIndex]?.question_id
          )}
          onResponseChange={handleResponseChange}
        />

        {/* Button Section */}
        <div className="flex mt-4 space-x-2 ">
          <button
            onClick={handleBack}
            disabled={currentQuestionIndex === 0}
            className="mr-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:opacity-50 transition duration-200"
          >
            Back
          </button>
          <button
            onClick={btnHandler}
            className={`px-4 py-2 text-white rounded transition duration-200 ${
              currentQuestionIndex === questionData.length - 1
                ? 'bg-orange-600 hover:bg-orange-700'
                : 'bg-orange-500 hover:bg-orange-600 disabled:opacity-50'
            }`}
          >
            {currentQuestionIndex === questionData.length - 1 ? "Let's Go" : "Next"}
          </button>
        </div>
      </div>

      {/* Right Side - Image Section */}
      <div className="flex items-center justify-center w-full md:w-6/12 bg-gray-200">
        <img src={websiteImg} alt="Website Preview"  loading="lazy" className="object-cover w-full h-full rounded-lg shadow-lg" />
      </div>
    </div>
    </>
  );
};

export default Quiz;
