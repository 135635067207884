import { create } from "zustand";

const useDataStore = create((set) => ({
  componentsData: null, // Initial state
  componentsMap: {},
  pagesData: null,
  pagesMap: {},

  setComponents: (newData, newMap) => {
    set({
      componentsData: newData,
      componentsMap: newMap,
    });
    // Save to local storage
    localStorage.setItem("componentsData", JSON.stringify(newData));
    localStorage.setItem("componentsMap", JSON.stringify(newMap));
  },
  initializeComponents: () => {
    console.log("INITIALIZING COMPONENTS");
    const savedData = localStorage.getItem("componentsData");
    const savedMap = localStorage.getItem("componentsMap");
    if (savedData) {
      set({ componentsData: JSON.parse(savedData) });
    }
    if (savedMap) {
      set({ componentsMap: JSON.parse(savedMap) });
    }
  },

  setPages: (newData, newMap) => {
    set({ pagesData: newData, pagesMap: newMap });
    // Save to local storage
    localStorage.setItem("pagesData", JSON.stringify(newData));
    localStorage.setItem("pagesMap", JSON.stringify(newMap));
  },
  initializePages: () => {
    console.log("INITIALIZING COMPONENTS");
    const savedData = localStorage.getItem("pagesData");
    const savedMap = localStorage.getItem("pagesMap");
    if (savedData) {
      set({ pagesData: JSON.parse(savedData) });
    }
    if (savedMap) {
      set({ pagesMap: JSON.parse(savedMap) });
    }
  },

  // setComponentsMap: (newMap) => {
  //   set({ componentsMap: newMap });
  //   localStorage.setItem("componentsMap", JSON.stringify(newMap)); // Save to local storage
  // },
  // initializeComponentsMap: () => {
  //   const savedMap = localStorage.getItem("componentsMap");
  //   if (savedMap) {
  //     set({ componentsMap: JSON.parse(savedMap) });
  //   }
  // },
  // setPagesMap: (newMap) => {
  //   set({ pagesMap: newMap });
  //   localStorage.setItem("pagesMap", JSON.stringify(newMap)); // Save to local storage
  // },
  // initializePagesMap: () => {
  //   const savedMap = localStorage.getItem("pagesMap");
  //   if (savedMap) {
  //     set({ pagesMap: JSON.parse(savedMap) });
  //   }
  // },
}));

export default useDataStore;

//   pageConfiguration : {
//     aboutus:{},
//     contactus:{},
//     home:{},
//     mastery:{},
//     features:{},
//     services:{},
//  },
//   storedRegisteredCardData: {},
//   storedPageTitleData: {},
// setPageConfiguration: (newConfig) =>
//     set((prevState) => {
//       if (typeof newConfig === "function") {
//         return { pageConfiguration: newConfig(prevState.pageConfiguration) };
//       }
//       return {
//         pageConfiguration: { ...prevState.pageConfiguration, ...newConfig },
//       };
//     }),

//   // Function to fetch component data

//   fetchComponentData: async (component) => {
//     const appId = localStorage.getItem("appId");
//     try {
//       const response = await axios.get(
//         `${BASE_URL}/api/component/get-by-compName/${appId}/${component}`
//       );
//       const data = response.data;
//       console.log("get_res", component, data);
//       const trainingCards = ["Training-card1", "Training-card2", "Training-card3"];
//       if (trainingCards.includes(component)) {
//         set({ storedRegisteredCardData: data });
//       } else if(component==="Training-heading") {
//         set({ storedPageTitleData: data });
//       }
//     } catch (error) {
//       Notification.error("Error fetching component data");
//       console.log(error);
//     }
//   },
