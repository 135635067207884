import useFormStore from "../../../store/useFormStore";
import customInstance from "../../../axios/axiosInstance";
import { useLocation } from "react-router-dom";
import FormLayoutButtons from "./FormLayoutButtons";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";
import { sortButton } from "../../../utils/formBuilderUtils/utils";
import Notification from "../../../services/Notification";
import axios from "axios";
import { useEffect, useState } from "react";
import { showSuccessToast,showWarningToast, showErrorToast } from "../../Toast/ToastNotification";
import { BASE_URL } from "../../../utils/utils/constants";

const FormPreview = ({ config, id }) => {
  const {
    formConfiguration: { elements, formName, formDescription },
    decrementStep,
    layout,
    setLayout,
    getLayoutClass,
    step,
    setStep,
    updateForm,
  } = useFormStore();

  const { pathname } = useLocation();

  const [loading, setLoading] = useState(false);

  const saveConfigurationHandler = async () => {
    if (elements.some((item) => item.FormElement === "Button")) {
      try {
        setLoading(true);
        const appId = localStorage.getItem("appId");
        const appName = localStorage.getItem("application_name");
        console.log("appName is",appName);
        const formConfiguration = { formName, formDescription, elements };
        
        // old - tim
        // const jsonPayload = {
        //   schemaName: `${appName}`,
        //   tableName: "component",
        //   data: {
        //     comp_type: "form", // This corresponds to the 'compType' field
        //     comp_name: "form", // This corresponds to the 'compName' field
        //     visible: true, // This corresponds to the 'visible' field
        //     page_id: "1", // This corresponds to the 'pageId' field
        //     app_id: `${appId}`, // This corresponds to the 'appId' field
        //     enabled: true, // This corresponds to the 'enabled' field
        //     json: {...formConfiguration},
        //   },
        // };

        //new by Abhihsek
        const jsonPayload = {
          appId: appId,
          compTempID: "form",
          pageId: "page1",
          compName: "form",
          compType: "form",
          visibilityRule: "always",
          visible: true,
          enabled: true,
          version: "1.0",
          json: { ...formConfiguration },
        }
        console.log("jsonPayload",jsonPayload);
        const res = await axios.post(
          `${BASE_URL}/component/save`,
          jsonPayload
        );
        
        const data = res.data;
        const id = data.split(":")[1];
        localStorage.setItem("formId", id);
        setLoading(false);
        console.log(data);
        // Notification.success("success your form was saved");
        if (res.status === 200) {
        showSuccessToast("Form saved successfully.");
      } 
        setStep(0);
        return;
      } catch (error) {
         console.log(error);
      if (error.response.status === 400) {
         showWarningToast("There was a problem with your request.");
      }else if(error.response.status === 500) {
        showErrorToast("Failed to save form");
      } else {
        console.error("Error uploading images:", error);
        showErrorToast("Failed to save form.");
      }
         setLoading(false);
        return;
    }
      // catch (error) {
      //console.log(error);
      //   Notification.error("something went wrong please try again later!!!");
      //   setLoading(false);
      //   return;
      // }

    }
    Notification.error("please add a button to your form");
  };

  if (loading) {
    return <div>loading...</div>;
  }
  if (!config) {
    return (
      <div className="flex justify-center align-center p-4 gap-6 md:w-2/5 w-full  rounded-lg flex-col">
        <button
          onClick={() => decrementStep()}
          className="self-start py-2 flex items-center justify-center gap-2 px-6 rounded-full border-blue-500 hover:bg-blue-500 duration-300 hover:text-white mt-2 mx-2"
        >
          <MdArrowBackIosNew />
          Back
        </button>
        {/* <FormLayoutButtons /> */}
        <form
          className={`max-w-xl bg-slate-50 mx-auto p-6 shadow-slate-200 rounded-md shadow-lg w-[80%] ${getLayoutClass(
            layout
          )}`}
        >
          <h4 className="text-slate-600  text-lg font-semibold mb-4">
            {/* Form Header */}
            {formName} Form
          </h4>
          {/* Dynamic Form Elements */}
          {sortButton(elements)?.map((item, index) => {
            const {
              FormElement,
              NameOfTheField,
              IsRequired,
              disabled,
              max_length,
              Action,
              url,
              VisibleFor,
            } = item;
            console.log("item :",item)

            switch (FormElement) {
              case "Text":
                return (
                  <div key={index} className="mb-6 flex flex-col items-start">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      {NameOfTheField}
                    </label>
                    <input
                      type="text"
                      name={NameOfTheField}
                      required={IsRequired}
                      disabled={true}
                      maxLength={max_length}
                      className="w-full p-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
                    />
                  </div>
                );

              case "Button":
                return (
                  <div key={index} className="mb-6">
                    <button
                      type="button"
                      disabled={true}
                      onClick={() => {
                        if (Action === "Open URL in New Window") {
                          window.open(url, "_blank");
                        }
                        // Handle other actions as needed
                      }}
                      className="px-4 py-2 bg-blue-500 text-white w-full rounded shadow-md hover:bg-blue-600 transition-colors duration-200"
                    >
                      {NameOfTheField}
                    </button>
                  </div>
                );

              case "Checkbox":
                return (
                  <div key={index} className="mb-6 flex">
                    {item.AddedNewRecords?.map((option, optIndex) => {
                      console.log(option.pyNote);
                      return (
                        <div className="w-full flex flex-row justify-center gap-4 items-center">
                          <label
                            key={optIndex}
                            className="flex items-center mb-1 "
                          >
                            {option.pyNote}
                          </label>

                          <input
                            type="checkbox"
                            name={NameOfTheField}
                            value={option.value}
                            required={IsRequired}
                            disabled={true}
                            className="mr-2"
                          />
                        </div>
                      );
                    })}
                  </div>
                );

              case "Dropdown":
                return (
                  <div key={index} className="mb-6">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      {NameOfTheField}
                    </label>
                    <select
                      name={NameOfTheField}
                      required={IsRequired}
                      disabled={true}
                      className="w-full p-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
                    >
                      {/* Replace with dynamic options */}
                      <option value="">Select an option</option>
                      {item.options?.map((option, optIndex) => (
                        <option key={optIndex} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                );

              case "Radio":
                return (
                  <div key={index} className="mb-6 flex">
                    {item.AddedNewRecords?.map((option, optIndex) => {
                      console.log(option.pyNote);
                      return (
                        <div className="w-full flex flex-row justify-center gap-4 items-center">
                          <label
                            key={optIndex}
                            className="flex items-center mb-1 "
                          >
                            {option.pyNote}
                          </label>

                          <input
                            type="radio"
                            name={NameOfTheField}
                            value={option.value}
                            required={IsRequired}
                            disabled={true}
                            className="mr-2"
                          />
                        </div>
                      );
                    })}
                  </div>
                );

              case "Image Icon":
                return (
                  <div key={index} className="mb-6">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      {NameOfTheField}
                    </label>
                    <input
                      type="file"
                      accept="image/*"
                      disabled={true}
                      className="w-full p-2 border rounded shadow-sm focus:outline-none"
                    />
                  </div>
                );

              case "Link":
                return (
                  <div key={index} className="mb-6">
                    <a
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 underline hover:text-blue-600"
                    >
                      {NameOfTheField}
                    </a>
                  </div>
                );

              default:
                return null;
            }
          })}
          {/* form description from state here */}

          {/* button to save form configuration to database */}
        </form>

        {/* <FormLayoutButtons /> */}
        <button
          type="button"
          onClick={
            pathname.includes("edit")
              ? () => updateForm(id)
              : saveConfigurationHandler
          }
          className="w-fit align-center px-4 py-3 font-bold bg-green-500 text-white rounded-full shadow-md hover:bg-green-600 transition-colors duration-200"
        >
          {pathname.includes("edit")
            ? "Edit Configuration"
            : "Save Configuration"}
        </button>
      </div>
    );
  }
  console.log("entered");
  return (
    <>
      <form
        className={`flex flex-col items-center justify-center mx-auto p-6 shadow-slate-100 shadow-sm hover:shadow-lg hover:shadow-slate-200  rounded-lg md:w-2/3 }`}
      >
        <h4 className="text-slate-600 text-lg font-bold mb-4">
          {config.formName} Form
        </h4>
        <h6 className="text-slate-600 text-md font-semibold mb-4">
          {config.formDescription}
        </h6>
        {/* Dynamic Form Elements this will be rendered if theres a config from the server */}
        {sortButton(config.elements)?.map((item, index) => {
          const {
            FormElement,
            NameOfTheField,
            IsRequired,
            disabled,
            max_length,
            Action,
            url,
            VisibleFor,
          } = item;

          switch (FormElement) {
            case "Text":
              return (
                <div
                  key={index}
                  className="mb-6 w-2/3 flex flex-col items-start"
                >
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    {NameOfTheField}
                  </label>
                  <input
                    type="text"
                    name={NameOfTheField}
                    required={IsRequired}
                    disabled={pathname === "/form"}
                    maxLength={max_length}
                    className="w-full p-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
                  />
                </div>
              );

            case "Button":
              return (
                <div key={index} className="mb-6">
                  <button
                    type="submit"
                    disabled={pathname === "/form"}
                    onClick={() => {
                      if (Action === "Open URL in New Window") {
                        return window.open(`${url}`, "_blank");
                      }
                      if (Action === "Save") {
                        const save = () => {
                          customInstance.post("/users", {
                            name: "name",
                            email: "email",
                          });
                        };
                        save();
                      }

                      // Handle other actions as needed
                    }}
                    className="px-4 py-2 bg-blue-500 text-white w-full rounded shadow-md hover:bg-blue-600 transition-colors duration-200"
                  >
                    {NameOfTheField}
                  </button>
                </div>
              );

            case "Checkbox":
              return (
                <div key={index} className="mb-6 flex">
                  {item.AddedNewRecords?.map((option, optIndex) => {
                    console.log(option.pyNote);
                    return (
                      <div className="w-full flex flex-row justify-center gap-4 items-center">
                        <label
                          key={optIndex}
                          className="flex items-center mb-1 "
                        >
                          {option.pyNote}
                        </label>

                        <input
                          type="checkbox"
                          name={NameOfTheField}
                          value={option.value}
                          required={IsRequired}
                          disabled={false}
                          className="mr-2"
                        />
                      </div>
                    );
                  })}
                </div>
              );

            case "Dropdown":
              return (
                <div key={index} className="mb-6">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    {NameOfTheField}
                  </label>
                  <select
                    name={NameOfTheField}
                    required={IsRequired}
                    disabled={pathname === "/form"}
                    className="w-full p-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
                  >
                    {/* Replace with dynamic options */}
                    <option value="">Select an option</option>
                    {item.options?.map((option, optIndex) => (
                      <option key={optIndex} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              );

            case "Radio":
              return (
                <div key={index} className="mb-6 flex">
                  {item.AddedNewRecords?.map((option, optIndex) => {
                    console.log(option.pyNote);
                    return (
                      <div className="w-full flex flex-row justify-center gap-4 items-center">
                        <label
                          key={optIndex}
                          className="flex items-center mb-1 "
                        >
                          {option.pyNote}
                        </label>

                        <input
                          type="radio"
                          name={NameOfTheField}
                          value={option.value}
                          required={IsRequired}
                          disabled={false}
                          className="mr-2"
                        />
                      </div>
                    );
                  })}
                </div>
              );

            case "Image Icon":
              return (
                <div key={index} className="mb-6">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    {NameOfTheField}
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    disabled={pathname === "/form"}
                    className="w-full p-2 border rounded shadow-sm focus:outline-none"
                  />
                </div>
              );

            case "Link":
              return (
                <div key={index} className="mb-6">
                  <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 underline hover:text-blue-600"
                  >
                    {NameOfTheField}
                  </a>
                </div>
              );

            default:
              return null;
          }
        })}
        {/* {pathname === "/form" && (
          <div className="flex justify-between mt-6 ">
            <button
              type="button"
              onClick={saveConfigurationHandler}
              className="px-4 py-2 bg-green-500 text-white rounded shadow-md hover:bg-green-600 transition-colors duration-200"
            >
              Save Configuration
            </button>
          </div>
        )} */}
      </form>
    </>
  );
};

export default FormPreview;