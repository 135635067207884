import React from "react";
import { Accordion } from "./Accordion/Accordion";
import { FaCaretDown, FaCog, FaPalette } from "react-icons/fa";
import { MdLabel } from "react-icons/md";
import AccordionItem from "./Accordion/Accordion";

export const getDefaultTimePickerConfig = () => ({
  placeholder: "Select Time",
  defaultTime: "12:00",
  timeFormat: "24-hour",
  stepInterval: 15,
  minTime: "",
  maxTime: "",
  width: "30",
  label: "Pick Your Time",
  disabled: false,
  required: false,
  tooltip: "",
  ActiveWhen: true,
  InactiveWhen: true,
  backgroundColor: "#ffffff",
  borderRadius: "4",
});

const TimePickerForm = ({ config, onConfigChange }) => {
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    onConfigChange((prevConfig) => ({
      ...prevConfig,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  return (
    <div>
      <Accordion>
        <AccordionItem
          Title={
            <>
              <MdLabel /> Basic Settings
            </>
          }
          panelName="Basic"
        >
          <label>
            Label:
            <input
              type="text"
              name="label"
              value={config.label}
              onChange={handleChange}
            />
          </label>
          <label>
            Default Time:
            <input
              type="time"
              name="defaultTime"
              value={config.defaultTime}
              onChange={handleChange}
            />
          </label>
         
          <label>
            Time Format:
            <select
              name="timeFormat"
              value={config.timeFormat}
              onChange={handleChange}
            >
              <option value="12-hour">12-Hour</option>
              <option value="24-hour">24-Hour</option>
            </select>
          </label>

          <label>
            Background Color:
            <input
              type="color"
              name="backgroundColor"
              value={config.backgroundColor}
              onChange={handleChange}
            />
          </label>
          <label>
            Border Radius:
            <input
              type="text"
              name="borderRadius"
              value={config.borderRadius}
              onChange={handleChange}
            />
          </label>
         
        </AccordionItem>

        <AccordionItem
          Title={
            <>
              <FaCog /> Advanced Settings
            </>
          }
          panelName="Advance"
        >
          <label>
            Hint Box:
            <input
              type="text"
              name="tooltip"
              value={config.tooltip}
              onChange={handleChange}
            />
          </label>
          <label>
            Width of the field:
            <input
              type="text"
              name="width"
              value={config.width}
              onChange={handleChange}
            />
          </label>
          <label>
            Disabled the field:
            <input
              type="checkbox"
              name="disabled"
              checked={config.disabled}
              onChange={handleChange}
            />
          </label>
          <label>
            Make the field mandatory :
            <input
              type="checkbox"
              name="required"
              checked={config.required}
              onChange={handleChange}
            />
          </label>
          <label>
            Active When:
            <input
              type="checkbox"
              name="activeWhen"
              checked={config.ActiveWhen}
              onChange={handleChange}
            />
          </label>
          <label>
            Inactive When:
            <input
              type="checkbox"
              name="inactiveWhen"
              checked={config.InactiveWhen}
              onChange={handleChange}
            />
          </label>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default TimePickerForm;
