import React, { useReducer } from 'react';
import './S.css';
import axios from 'axios';
import HinputField from './ReuseableItems/HinputField';
import Hbutton from './ReuseableItems/Hbutton';
import useFormValidation from './ReuseableItems/useFormValidation';
import {showSuccessToast, showWarningToast, showErrorToast} from '../../Toast/ToastNotification';

import { BASE_URL } from '../../../utils/utils/constants';

const initialState = {
  logoType: 'siteName',
  siteName: '',
  uploadedLogo: null,
  navLinks: [],
  newNavLink: { navlabel: '', routeto: '' },
  searchBar: false,
  searchType: 'basic',
  headerElements: {
    cart: false,
    userAccount: false,
  },
  layoutStyle: 'simple',
  stickyHeader: false,
  socialMediaLinks: [],
  newSocialMedia: { platform: '', url: '' },
};

const configuratorReducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOGO_TYPE':
      return { ...state, logoType: action.payload };
    case 'SET_SITE_NAME':
      return { ...state, siteName: action.payload };
    case 'SET_UPLOADED_LOGO':
      return { ...state, uploadedLogo: action.payload };
    case 'ADD_NAV_LINK':
      return {
        ...state,
        navLinks: [...state.navLinks, state.newNavLink],
        newNavLink: { navlabel: '', routeto: '' },
      };
    case 'SET_NEW_NAV_LINK':
      return { ...state, newNavLink: action.payload };
    case 'TOGGLE_SEARCH_BAR':
      return { ...state, searchBar: action.payload };
    case 'SET_SEARCH_TYPE':
      return { ...state, searchType: action.payload };
    case 'TOGGLE_HEADER_ELEMENT':
      return {
        ...state,
        headerElements: {
          ...state.headerElements,
          [action.payload]: !state.headerElements[action.payload],
        },
      };
    case 'SET_LAYOUT_STYLE':
      return { ...state, layoutStyle: action.payload };
    case 'TOGGLE_STICKY_HEADER':
      return { ...state, stickyHeader: action.payload };
    case 'ADD_SOCIAL_MEDIA':
      return {
        ...state,
        socialMediaLinks: [...state.socialMediaLinks, state.newSocialMedia],
        newSocialMedia: { platform: '', url: '' },
      };
    case 'SET_NEW_SOCIAL_MEDIA':
      return { ...state, newSocialMedia: action.payload };
    default:
      return state;
  }
};

// const [showErrors, setShowErrors] = useState(false);

const HeaderConfigForm = () => {
  const [state, dispatch] = useReducer(configuratorReducer, initialState);
  const { errors, validate, clearError } = useFormValidation(state, 'headerConfig');
  const handleSaveConfiguration = async () => {
    if (validate()) {
      const jsonData = {
        jsonData: state // This assumes that the state object is the data structure you want to save
      };
  
      try {
        const response = await axios.post(
          `${BASE_URL}/save-component-json`,
          jsonData,
          {
            headers: {
              "Content-Type": "application/json", // Correct header for JSON
            },
          }
        );
        console.log('Data successfully sent:', response.data);
        if (response.status === 200) {
          showSuccessToast("Data saved successfully");
        }
      } catch (error) {
        if (error.response.status === 400) {
          showWarningToast("There was a problem with your request.");
        } else if (error.response.status === 500) {
          showErrorToast("Failed to save data");
        } else {
          console.error("Error saving data:", error);
          showErrorToast("Failed to save data.");
        }
        console.error('Error sending data:', error.response ? error.response.data : error.message);
      }
    } else {
      console.log('Validation failed:', errors);
    }
  };
  

  return (
    <div className="configurator-container">
      <h1>Header Configuration</h1>

      <div className="config-main">
        <fieldset className="config-fieldset">
          <legend>Logo and Branding</legend>
          <HinputField
            type="radio"
            label="Use Site Name as Text Logo"
            name="logoType"
            value="siteName"
            checked={state.logoType === 'siteName'}
            onChange={() => {
              dispatch({ type: 'SET_LOGO_TYPE', payload: 'siteName' });
              clearError('logoType');
            }}
          />
          <HinputField
            type="radio"
            label="Upload Logo"
            name="logoType"
            value="uploadLogo"
            checked={state.logoType === 'uploadLogo'}
            onChange={() => {
              dispatch({ type: 'SET_LOGO_TYPE', payload: 'uploadLogo' });
              clearError('logoType');
            }}
          />
          {state.logoType === 'siteName' && (
            <>
              <HinputField
                type="text"
                label="Enter Site Name"
                name="siteName"
                value={state.siteName}
                onChange={(e) => {
                  dispatch({ type: 'SET_SITE_NAME', payload: e.target.value });
                  clearError('siteName');
                }}
                placeholder="Enter Site Name"
              />
              {errors.siteName && <span className="error">{errors.siteName}</span>}
            </>
          )}

          {state.logoType === 'uploadLogo' && (
            <>
              <HinputField
                type="file"
                name="uploadedLogo"
                onChange={(e) => {
                  dispatch({ type: 'SET_UPLOADED_LOGO', payload: e.target.files[0] });
                  clearError('uploadedLogo');
                }}
              />
              {errors.uploadedLogo && <span className="error">{errors.uploadedLogo}</span>}
            </>
          )}
        </fieldset>
        <fieldset className="config-fieldset">
          <legend>Header Elements</legend>
          <HinputField
            type="checkbox"
            label="Include Cart"
            name="cart"
            checked={state.headerElements.cart}
            onChange={() => dispatch({ type: 'TOGGLE_HEADER_ELEMENT', payload: 'cart' })}
          />
          <HinputField
            type="checkbox"
            label="Include User Account"
            name="userAccount"
            checked={state.headerElements.userAccount}
            onChange={() => dispatch({ type: 'TOGGLE_HEADER_ELEMENT', payload: 'userAccount' })}
          />
        </fieldset>
        <fieldset className="config-fieldset">
          <legend>Layout Style</legend>
          <HinputField
            type="radio"
            label="Simple Layout"
            name="layoutStyle"
            value="simple"
            checked={state.layoutStyle === 'simple'}
            onChange={() => dispatch({ type: 'SET_LAYOUT_STYLE', payload: 'simple' })}
          />
          <HinputField
            type="radio"
            label="Complex Layout"
            name="layoutStyle"
            value="complex"
            checked={state.layoutStyle === 'complex'}
            onChange={() => dispatch({ type: 'SET_LAYOUT_STYLE', payload: 'complex' })}
          />
        </fieldset>
        <fieldset className="config-fieldset">
          <legend>Sticky Header</legend>
          <HinputField
            type="checkbox"
            label="Enable Sticky Header"
            name="stickyHeader"
            checked={state.stickyHeader}
            onChange={(e) => dispatch({ type: 'TOGGLE_STICKY_HEADER', payload: e.target.checked })}
          />
        </fieldset>
      </div>
      <Hbutton
        type="button"
        label="Save Configuration"
        onClick={handleSaveConfiguration}
        id="submit-btn"
      />
    </div>
  );
};

export default HeaderConfigForm;
