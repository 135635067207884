import React from "react";
import {
  FaTextHeight,
  FaFont,
  FaAlignLeft,
  FaAlignCenter,
  FaAlignRight,
  FaBold,
  FaRegCircle,
  FaRegSquare,
  FaPaintBrush,
} from "react-icons/fa";
import ParagraphPreview from "../previews/ParagraphPreview";
import AccordionItem from "./Accordion/Accordion";
import { MdLabel } from "react-icons/md";

const ParagraphForm = ({ config, onConfigChange }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    const parsedValue = e.target.type === "number" ? parseFloat(value) : value;
    onConfigChange({ ...config, [name]: parsedValue });
  };

  return (
    <div className="p-6 max-w-xl mx-auto bg-white shadow-lg rounded-lg space-y-4">
      <h3 className=" font-semibold text-center">Paragraph Component</h3>

      {/* Accordion 1: Text Settings */}
      <AccordionItem Title={<><MdLabel /> Text Settings</>} panelName="Basic">
        <div className="space-y-4 mt-4">
          <div className="flex items-center space-x-2">
            <FaFont className="text-xl text-gray-600" />
            <label className="w-1/3">Font Size :</label>
            <input
              type="number"
              name="fontSize"
              value={config.fontSize || 14}
              onChange={handleChange}
              min={1}
              className="w-24 p-2 border border-gray-300 rounded-lg"
            />
          </div>

          <div className="flex items-center space-x-2">
            <FaBold className="text-xl text-gray-600" />
            <label className="w-1/3">Font Weight:</label>
            <select
              name="fontWeight"
              value={config.fontWeight || "normal"}
              onChange={handleChange}
              className="w-24 p-2 border border-gray-300 rounded-lg"
            >
              <option value="normal">Normal</option>
              <option value="bold">Bold 🅱️</option>
              <option value="lighter">Lighter 🔦</option>
            </select>
          </div>

          <div className="flex items-center space-x-2">
            <FaAlignCenter className="text-xl text-gray-600" />
            <label className="w-1/3">Font Family:</label>
            <select
              name="fontFamily"
              value={config.fontFamily || "Arial"}
              onChange={handleChange}
              className="w-24 p-2 border border-gray-300 rounded-lg"
            >
              <option value="Arial">Arial</option>
              <option value="Georgia">Georgia</option>
              <option value="Times New Roman">Times New Roman</option>
              <option value="Courier New">Courier New</option>
              <option value="Verdana">Verdana</option>
            </select>
          </div>
        </div>
      </AccordionItem>

      {/* Accordion 2: Spacing and Alignment */}
      <AccordionItem Title={<><MdLabel />Alignment Settings</>} panelName="Basic" >
        <div className="space-y-4 mt-4">
          <div className="flex items-center space-x-2">
            <FaTextHeight className="text-xl text-gray-600" />
            <label className="w-1/3">Text Spacing :</label>
            <input
              type="number"
              name="lineHeight"
              value={config.lineHeight || 1.5}
              onChange={handleChange}
              min={0.1}
              step={0.1}
              className="w-24 p-2 border border-gray-300 rounded-lg"
            />
          </div>

          <div className="flex items-center space-x-2">
            <FaAlignLeft className="text-xl text-gray-600" />
            <label className="w-1/3">Text Alignment:</label>
            <select
              name="textAlign"
              value={config.textAlign || "left"}
              onChange={handleChange}
              className="w-24 p-2 border border-gray-300 rounded-lg"
            >
              <option value="left">⬅️ Left</option>
              <option value="center">⬅️ Center ➡️</option>
              <option value="right">Right ➡️</option>
              <option value="justify">Justify</option>
            </select>
          </div>

          <div className="flex items-center space-x-2">
            <FaRegCircle className="text-xl text-gray-600" />
            <label className="w-1/3">Outer Space :</label>
            <input
              type="number"
              name="margin"
              value={config.margin || 0}
              onChange={handleChange}
              min={0}
              className="w-24 p-2 border border-gray-300 rounded-lg"
            />
          </div>

          <div className="flex items-center space-x-2">
            <FaRegSquare className="text-xl text-gray-600" />
            <label className="w-1/3">Inner Space :</label>
            <input
              type="number"
              name="padding"
              value={config.padding || 0}
              onChange={handleChange}
              min={0}
              className="w-24 p-2 border border-gray-300 rounded-lg"
            />
          </div>
        </div>
      </AccordionItem>

      {/* Accordion 3: Colors */}
      <AccordionItem Title={<><MdLabel />Colors Settings</>} panelName="Basic" >
        <div className="space-y-4 mt-4">
          <div className="flex items-center space-x-2">
            <FaPaintBrush className="text-xl text-gray-600" />
            <label className="w-1/3">Color:</label>
            <input
              type="color"
              name="color"
              value={config.color || "#333333"}
              onChange={handleChange}
              className="w-24 p-2 border border-gray-300 rounded-lg"
            />
          </div>

          <div className="flex items-center space-x-2">
            <FaPaintBrush className="text-xl text-gray-600" />
            <label className="w-1/3">Background Color:</label>
            <input
              type="color"
              name="backgroundColor"
              value={config.backgroundColor || "#ffffff"}
              onChange={handleChange}
              className="w-24 p-2 border border-gray-300 rounded-lg"
            />
          </div>
        </div>
      </AccordionItem>
    </div>
  );
};

export const getDefaultParagraphConfig = () => ({
  text: "",
  fontSize: 14,
  lineHeight: 1.5,
  margin: 0,
  padding: 0,
  color: "#333333",
  backgroundColor: "#ffffff",
  fontWeight: "normal",
  textAlign: "left",
  fontFamily: "Arial",
});

export default ParagraphForm;
export { ParagraphPreview };
