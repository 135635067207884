import React, { useState, useEffect } from "react";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import Notification from "../../services/Notification";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../header/Header";
import axios from "axios";
import "./ResetPassword.css";
import { showErrorToast, showSuccessToast, showWarningToast } from "../Toast/ToastNotification";
import { BASE_URL } from "../../utils/utils/constants";

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isResetroute = location.pathname.includes("/auth/resetPassword");

  const [token, setToken] = useState(null);
  const [previousPassword, setPreviousPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [showStatus, setShowStatus] = useState({
    currPassword: false,
    newPassword: false,
    confPassword: false,
  });

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;
    if (!email.trim()) {
      setEmailError("Email is required");
      showWarningToast("Warning", "Email is required");
      return false;
    }
    if (!emailRegex.test(email.trim())) {
      setEmailError("Email is invalid");
      showWarningToast("Warning", "Email is invalid");
      return false;
    }
    setEmailError("");
    return true;
  };

  const validatePassword = () => {
    const trimmedPassword = newPassword.trim();

    if (!trimmedPassword) {
      setPasswordError("Password is required");
      showWarningToast("Warning", "Password is required");
      return false;
    }

    if (trimmedPassword.length < 8) {
      setPasswordError(
        "Password must be at least 8 characters long and include a special character , a uppercase and a number."
      );
      showWarningToast(
        "Warning",
        "Password must be at least 8 characters long and include a special character , a uppercase and a number."
      );
      return false;
    }

    const numberRegex = /[0-9]/;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;

    if (!numberRegex.test(trimmedPassword)) {
      setPasswordError(
        "Password must be at least 8 characters long and include a special character , a uppercase and a number."
      );
      showWarningToast(
        "Warning",
        "Password must be at least 8 characters long and include a special character , a uppercase and a number."
      );
      return false;
    }

    if (!specialCharRegex.test(trimmedPassword)) {
      setPasswordError(
        "Password must be at least 8 characters long and include a special character , a uppercase and a number."
      );
      showWarningToast(
        "Warning",
        "Password must be at least 8 characters long and include a special character , a uppercase and a number."
      );
      return false;
    }

    if (newPassword !== confirmPassword) {
      showWarningToast(
        "Warning",
        "New password and confirm password must be same!"
      );
      return false;
    }

    setPasswordError("");
    return true;
  };

  const fetchToken = () => {
    const params = new URLSearchParams(window.location.search);
    const rawToken = params.get("token");
    const rawEmail = params.get("email");

    if (rawToken || rawEmail) {
      // Check if rawToken is not null
      const t = rawToken.split(":")[1]?.trim();
      setToken(t);
      setEmail(rawEmail);
    } else {
      console.error("Token is not available in the URL parameters.");
    }
  };
  useEffect(() => {
    fetchToken();
  }, []);

  const handleSubmit = async (e) => {
    console.log("6", token);
    e.preventDefault();
    const isEmailValid = validateEmail();
    const isPasswordValid = validatePassword();

    if (isEmailValid && isPasswordValid) {
      try {
        
        const response = await axios.post(
          `${BASE_URL}/reset-password?token=${token}&newPassword=${newPassword}`
        );
        console.log(response.data);
        // Notification.success("success", "Password reset successfull.");
        if (response.status === 200) {
          showSuccessToast("Password reset successfull");
        }
        navigate("/auth/signin");
      } catch (error) {
        // Notification.error("Failed to reset the password");
        // Check if error.response and error.response.data are defined
        if (error.response.status === 400) {
          showWarningToast("There was a problem with your request.");
        } else if (error.response.status === 500) {
          showErrorToast("Failed to reset the password");
        } else {
          console.error("Error in  reset password:", error);
          showErrorToast("Failed to reset the password.");
        }
        const errorMessage = error.response?.data || error.message;

        if (error.response) {
          const statusCode = error.response.status;
          const errorData = error.response.data || {}; // Default to an empty object if undefined

          // Check if errorData.error is defined before using includes
          if (statusCode === 400) {
            if (errorData && errorData.includes("Invalid email format")) {
              showErrorToast("Please enter a valid email address.");
            } else if (errorData && errorData.includes("Invalid token")) {
              showErrorToast ("Invalid token.");
            } else {
              showErrorToast("Invalid request. Please check your input.");
            }
          } else if (statusCode === 404) {
            showErrorToast(
            
              "The requested resource was not found. Please check the URL or try again later."
            );
            console.log("Requested path:", errorData.path);
          } else if (statusCode === 429) {
            showWarningToast("Too many reset attempts. Please wait a few minutes before trying again."
            );
          } else if (statusCode === 403) {
            if (errorData && errorData.includes("Expired reset token")) {
              showWarningToast(
                "Warning",
                "Your reset link has expired. Please request a new one."
              );
            } else if (errorData && errorData.includes("Invalid reset token")) {
              showErrorToast("Invalid password reset link. Please request a new link.");
            } else {
              showErrorToast ("Forbidden request.");
            }
          } else if (statusCode === 500) {
            showErrorToast("There was a problem on our end. Please try again later.");
          } else {
            showErrorToast(errorData.error || "Something went wrong. Please try again.");
          }
        } else if (error.request) {
          showErrorToast("Network error. Please check your internet connection and try again.");
        } else {
          showErrorToast("Unexpected error occurred. Please try again later.");
        }
      }
    }
  };
  return (
    <>
      <div className="w-full h-screen bg-wight-500 flex justify-center items-center">
        <div className="w-full max-w-[700px] bg-[#2c2c2c] rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 border-gray-300">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-4xl font-bold text-center leading-tight tracking-tight mb-8 text-white md:text-3xl">
              Reset Password
            </h1>
            <div>
              <form onSubmit={handleSubmit} className="space-y-4 md:space-y-6">
                <div className="w-full relative">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    name="email"
                    className={`reset-field block mt-8 w-full h-[50px] px-4 bg-[#3b3b3b] border-b-2 border-[#555] rounded-md text-base text-[#f0f0f0] transition-all ease-in-out duration-300 ${
                      emailError
                        ? "border-b-3 border-red-600"
                        : " border-[#555]"
                    } focus:outline-none focus:border-b-3 focus:border-blue-300 `}
                    placeholder=""
                  />
                  <label className="reset-label">Your email</label>

                  {/*           {emailError && <p className="error-message">{emailError}</p>} */}
                </div>

                {!isResetroute && (
                  <div className="relative">
                    <label
                      for="prevPassword"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Current password
                    </label>
                    <input
                      type={showStatus.currPassword ? "text" : "password"}
                      value={previousPassword}
                      onChange={(e) => setPreviousPassword(e.target.value)}
                      required
                      name="prevPassword"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="enter current password"
                    />

                    {showStatus.currPassword ? (
                      <BsFillEyeFill
                        onClick={() => {
                          setShowStatus((prevState) => ({
                            ...prevState,
                            currPassword: false,
                          }));
                        }}
                        className="cursor-pointer absolute top-10 text-gray-400  right-2 "
                      />
                    ) : (
                      <BsFillEyeSlashFill
                        onClick={() => {
                          setShowStatus((prevState) => ({
                            ...prevState,
                            currPassword: true,
                          }));
                        }}
                        className="cursor-pointer absolute top-10 text-gray-400  right-2 "
                      />
                    )}
                  </div>
                )}

                <div className="w-full relative">
                  <div className="relative">
                    <input
                      type={showStatus.newPassword ? "text" : "password"}
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      required
                      name="newPassword"
                      className={`reset-field block mt-8 w-full h-[50px] px-4 bg-[#3b3b3b] border-b-2 border-[#555] rounded-md text-base text-[#f0f0f0] transition-all ease-in-out duration-300 ${
                        passwordError
                          ? "border-b-3 border-red-600"
                          : " border-[#555]"
                      } focus:outline-none focus:border-b-3 focus:border-blue-300 `}
                      placeholder=""
                    />
                    <label className="reset-label">New password</label>

                    {/*                 {passwordError && ( */}
                    {/*                   <p className="error-message">{passwordError}</p> */}
                    {/*                 )} */}
                    {showStatus.newPassword ? (
                      <BsFillEyeFill
                        onClick={() => {
                          setShowStatus((prevState) => ({
                            ...prevState,
                            newPassword: false,
                          }));
                        }}
                        className="cursor-pointer absolute top-4  text-blue-300 right-2 hover:text-blue-300 focus:text-blue-300   "
                      />
                    ) : (
                      <BsFillEyeSlashFill
                        onClick={() => {
                          setShowStatus((prevState) => ({
                            ...prevState,
                            newPassword: true,
                          }));
                        }}
                        className="cursor-pointer absolute top-4  text-gray-300 right-2 "
                      />
                    )}
                  </div>
                </div>
                <div className="w-full relative">
                  <div className="relative">
                    <input
                      type={showStatus.confPassword ? "text" : "password"}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                      name="confirmPassword"
                      className={`reset-field block mt-8 w-full h-[50px] px-4 bg-[#3b3b3b] border-b-2 border-[#555] rounded-md text-base text-[#f0f0f0] transition-all ease-in-out duration-300 ${
                        passwordError
                          ? "border-b-3 border-red-600"
                          : " border-[#555]"
                      } focus:outline-none focus:border-b-3 focus:border-blue-300 `}
                      placeholder=""
                    />
                    <label className="reset-label">Confirm password</label>
                    {showStatus.confPassword ? (
                      <BsFillEyeFill
                        onClick={() => {
                          setShowStatus((prevState) => ({
                            ...prevState,
                            confPassword: false,
                          }));
                        }}
                        className="cursor-pointer absolute top-4 text-blue-300 right-2 hover:text-blue-300 focus:text-blue-300   "
                      />
                    ) : (
                      <BsFillEyeSlashFill
                        onClick={() => {
                          setShowStatus((prevState) => ({
                            ...prevState,
                            confPassword: true,
                          }));
                        }}
                        className="cursor-pointer absolute top-4 text-gray-300  right-2 "
                      />
                    )}
                  </div>
                </div>

                <button
                  type="submit"
                  className="w-full bg-[#1e90ff] text-white mt-7 font-bold text-[1.2rem] py-2.5 px-5 rounded-md cursor-pointer transition-colors duration-300 hover:bg-[#1c86ee]"
                >
                  Reset password
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
