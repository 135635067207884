import React, { useState } from "react";

const PasswordInputPreview = ({ config }) => {
    const {
        placeholder,
        width,
        fontSize,
        color,
        backgroundColor,
        borderStyle,
        borderColor,
        borderRadius,
        padding,
    } = config;

    // Local state for managing the password input value
    const [inputValue, setInputValue] = useState("");

    const handleChange = (e) => {
        setInputValue(e.target.value);
    };

    return (
        <div style={{ marginTop: "20px" }}>
            <h4>Preview:</h4>
            <input
                type="password"
                placeholder={placeholder || ""}
                value={inputValue}
                onChange={handleChange}
                style={{
                    width: `${width}px`,
                    fontSize: `${fontSize}px`,
                    color,
                    backgroundColor,
                    borderColor,
                    borderRadius: `${borderRadius}px`,
                    padding: `${padding}px`,
                }}
            />
        </div>
    );
};

export default PasswordInputPreview;
